import React, { FC } from 'react';
import cx from 'classnames';
import { TileGroup as TileGroupProps } from './TileGroup.types';
import styles from './TileGroup.module.scss';
import { Grid, Column } from 'src/components/Layout/Grid';

const TileGroup: FC<TileGroupProps> = ({
  label,
  title,
  subtitle,
  children,
  useThreadsGrid = false,
}) => {
  return (
    <Grid useThreadsGrid={useThreadsGrid}>
      <Column>
        <div className={styles.tileGroupContainer}>
          <h2 className={cx(styles.label, 'h6')}>{label}</h2>
          <h3 className={cx(styles.title, 'h2')}>{title}</h3>
          {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
          <div className={styles.tileContainer}>{children}</div>
        </div>
      </Column>
    </Grid>
  );
};

export default TileGroup;
