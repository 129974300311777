import React, { useEffect, useState } from 'react';

// components
import { Grid, Column } from 'src/components/Layout';
import { LocaleSwitch } from 'src/components/Inputs';
import { Drawer } from 'src/components/Feedback';

// styles
import styles from './UnidentifiedTraffic.module.scss';

// hooks
import { useDeviceSize, useUserLocale } from 'src/hooks';

const UnidentifiedTraffic = () => {
  const device = useDeviceSize();
  const { locale } = useUserLocale();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // delay opening the drawer
    const timer = setTimeout(() => {
      if (!locale?.localeCode && device.isMedium) {
        setIsOpen(true);
      }
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [locale, device.isMedium]);

  if (isOpen) {
    return (
      <Drawer>
        <Grid>
          <Column small={12} className={styles.allowOverflow}>
            <p>Where are you browsing from?</p>
            <LocaleSwitch lightMode={true} />
          </Column>
        </Grid>
      </Drawer>
    );
  }
  return null;
};

export default UnidentifiedTraffic;
