import React, { FC, useState } from 'react';
import styles from './Accordion.module.scss';
import ChevronUpS1 from 'plaid-threads/Icons/ChevronUpS1';
import ChevronDownS1 from 'plaid-threads/Icons/ChevronDownS1';
import { nanoid } from 'nanoid';
import { Column, Grid } from 'src/components/Layout';
import { RichTextRenderer } from 'src/components/RichTextRenderer';
import { RichTextContent } from 'contentful';
import cx from 'classnames';
export interface AccordionItemProps {
  title: string;
  content: string | RichTextContent;
}

const Accordion: FC<AccordionItemProps> = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const accordionId = `accordion-${nanoid()}`;

  return (
    <Grid containerClassName={styles.accordion} noPadding>
      <Column>
        <button
          className={styles.accordionToggle}
          onClick={handleClick}
          aria-expanded={isExpanded}
          aria-controls={accordionId}
          type='button'
          id={`${accordionId}-control`}
        >
          <Grid noPadding className={styles.titleSection}>
            <Column small={8} className={styles.title}>
              <h3 className={cx('h5')}>{title}</h3>
            </Column>
            <Column small={4} medium={1} className={styles.iconColumn}>
              <span className={styles.icon}>
                {isExpanded ? <ChevronUpS1 /> : <ChevronDownS1 />}
              </span>
            </Column>
          </Grid>
        </button>
      </Column>
      <Column>
        <Grid noPadding>
          <Column medium={8}>
            <div
              className={styles.content}
              id={accordionId}
              aria-labelledby={`${accordionId}-control`}
              role='region'
              style={{
                display: isExpanded ? 'block' : 'none',
              }}
            >
              {typeof content === 'string' ? (
                <p
                  className={styles.contentParagraph}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              ) : (
                <RichTextRenderer content={content} />
              )}
            </div>
          </Column>
        </Grid>
      </Column>
    </Grid>
  );
};

export default Accordion;
