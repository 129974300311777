import React, { useState, useRef } from 'react';
import useMeasure from 'react-use-measure';
import { useSwipeable } from 'react-swipeable';
import { useDebouncedCallback } from 'use-debounce';
import { useDeviceSize } from 'src/hooks';
import { animated, easings, useSprings } from '@react-spring/web';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Sys } from 'contentful';
import { default as LinkCarouselBtns } from './LinkCarouselBtns';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { getSlideStyles } from './linkCarouselStyles';
import { StationaryLinkCarousel } from './StationaryLinkCarousel';
import _clamp from 'lodash/clamp';
import {
  Stack,
  Column,
  Section,
  Container,
  Row,
  Spacer,
} from 'src/components-v2/Layout';

const AnimatedStack = animated(Stack);

interface ISlide {
  content: React.ReactElement;
  sx?: SxProps<Theme>;
}

interface ILinkCarousel {
  slides: Array<ISlide>;
  heading?: React.ReactElement;
  sx?: SxProps<Theme>;
  sys?: Sys;
}

const LinkCarousel: React.FC<ILinkCarousel> = ({
  slides,
  heading,
  sx,
  sys,
}) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  const initialState = slides.map((slide, index) => {
    return {
      isActive: false,
    };
  });
  const [state, setState] = useState(initialState);
  const device = useDeviceSize();
  const [contentRef, contentBounds] = useMeasure();
  const [leftPosition, setLeftPosition] = useState(0);
  const [clickCount, setClickCount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [gapUnit, setGapUnit] = useState(0);
  const cardsPerPage = device.isSmall ? 1 : device.isXLarge ? 3 : 2;
  const gapPx = gapUnit * 8;
  const numberOfCards = slides.length;
  const cardWidth = contentBounds.width / cardsPerPage - gapPx;
  const lastWidth = useRef(cardWidth);

  const previousCard = () => {
    if (clickCount === 0) {
      // need this because the swipe right uses this function (the button is disabled when clickCount === 0)
      return;
    }
    setLeftPosition((clickCount - 1) * (-cardWidth - gapPx));
    setClickCount(clickCount - 1);
  };

  const nextCard = () => {
    if (clickCount >= numberOfCards - cardsPerPage) {
      // need this because the swipe left uses this function (the button is disabled when clickCount >= numberOfCards - cardsPerPage)
      return;
    }
    setLeftPosition((clickCount + 1) * (-cardWidth - gapPx));
    setClickCount(clickCount + 1);
  };

  const focusOnCard = (i) => {
    const selectedCard = _clamp(i, 0, numberOfCards - cardsPerPage);
    setLeftPosition(selectedCard * (-cardWidth - gapPx));
    setClickCount(selectedCard);
    setIsHovered(true);
    setState((prevState) => {
      return prevState.map((item, index) => {
        return { isActive: index === i ? true : false };
      });
    });
  };

  const repositionSlides = useDebouncedCallback(() => {
    if (!device.isSmall && clickCount > numberOfCards - cardsPerPage) {
      setClickCount(numberOfCards - cardsPerPage);
    }

    const delta = (cardWidth + gapPx) * clickCount;
    const modifier = lastWidth.current < cardWidth ? -1 : 1;
    const adjustedPosition = 0 + modifier * delta;
    setLeftPosition(adjustedPosition);
  }, 100);

  const handleHover = (i) => {
    setIsHovered(true);
    setState((prevState) => {
      return prevState.map((item, index) => {
        return { isActive: index === i ? true : false };
      });
    });
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setIsHovered(false);
      nextCard();
    },
    onSwipedRight: () => {
      setIsHovered(false);
      previousCard();
    },
    delta: 10, // min distance(px) before a swipe starts.
    preventScrollOnSwipe: true, // prevents scroll during swipe
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
    swipeDuration: 1000, // allowable duration of a swipe (ms)
    touchEventOptions: { passive: true }, // options for touch listeners
  });

  React.useEffect(() => {
    const gap = device.isSmall ? 2.5 : device.isXLarge ? 5 : 4;
    setGapUnit(gap);
    window.addEventListener('resize', repositionSlides);
    return () => {
      window.removeEventListener('resize', repositionSlides);
    };
  }, [device.isSmall, device.isXLarge, repositionSlides]);

  const [springs] = useSprings(
    slides.length,
    (index) => {
      return {
        from: { filter: 'grayscale(0%)', opacity: '1' },
        to: {
          filter:
            isHovered && !state[index].isActive
              ? 'grayscale(100%)'
              : 'grayscale(0%)',
          opacity: isHovered && !state[index].isActive ? '0.6' : '1',
        },
        config: {
          duration: 300,
          easing: easings.easeInQuad,
        },
      };
    },
    [isHovered, state],
  );

  return (
    <Section
      className='cms-link-carousel'
      sx={{
        ...sx,
      }}
      {...sx}
    >
      {slides.length <= 3 && heading}
      {slides.length > 3 && (
        <Container>
          <Row
            sx={{
              alignItems: 'flex-end',
            }}
          >
            <Column
              xsOffset={2}
              xs={22}
              smOffset={1}
              sm={14}
              mdOffset={1}
              md={16}
              lgOffset={2}
              lg={16}
              sx={{
                mb: { xs: '28px', sm: 0 },
              }}
            >
              {heading && heading}
            </Column>
            <Column
              xsOffset={2}
              xs={22}
              smOffset={0}
              sm={9}
              mdOffset={0}
              md={7}
              lgOffset={0}
              lg={4}
              sx={{
                display: 'flex',
                justifyContent: { sm: 'flex-end' },
              }}
            >
              <LinkCarouselBtns
                clickCount={clickCount}
                previousCard={previousCard}
                nextCard={nextCard}
                maxCount={numberOfCards - cardsPerPage}
              />
            </Column>
          </Row>
        </Container>
      )}
      <Container>
        <Row>
          <Column
            xs={19}
            xsOffset={1}
            sm={20}
            lgOffset={2}
            lg={20}
            ref={contentRef}
            sx={{ p: 0 }}
          >
            <Spacer sx={{ height: { xs: '28px', lg: '45px' } }} />
          </Column>
        </Row>
      </Container>
      <Container {...inspectorProps({ fieldId: 'internalTitle' })}>
        <Row>
          {slides.length > 3 && (
            <>
              <Column {...handlers} xs={22} xsOffset={1} lgOffset={2} lg={21}>
                <Stack
                  direction='row'
                  gap={gapUnit}
                  justifyContent='space-between'
                  sx={{
                    transform: `translateX(${leftPosition}px)`,
                    transition: 'transform 0.3s ease-in-out',
                  }}
                >
                  {slides.map((slide, index) => {
                    return (
                      <div
                        onFocus={() => {
                          focusOnCard(index);
                        }}
                        onBlur={() => {
                          return setIsHovered(false);
                        }}
                        onMouseEnter={() => {
                          handleHover(index);
                        }}
                        onMouseLeave={() => {
                          handleLeave();
                        }}
                      >
                        <AnimatedStack
                          key={`slide${index}`}
                          style={springs[index]}
                          sx={{
                            width: `${cardWidth}px`,
                            alignItems: 'flex-start',
                            flex: '0 0 auto',
                            ...getSlideStyles(isHovered),
                            ...slide.sx,
                          }}
                        >
                          {slide.content}
                        </AnimatedStack>
                      </div>
                    );
                  })}
                </Stack>
              </Column>
            </>
          )}
          {slides.length < 4 && ( // use the grid for 3 or less cards and eliminate button and swipe features
            <StationaryLinkCarousel
              slides={slides}
              springs={springs}
              focusOnCard={focusOnCard}
              setIsHovered={setIsHovered}
              handleHover={handleHover}
              handleLeave={handleLeave}
              isHovered={isHovered}
            />
          )}
        </Row>
      </Container>
    </Section>
  );
};

export default LinkCarousel;
