import React from 'react';
import { Sys, RichTextContent } from 'contentful';
import _get from 'lodash/get';
import { Stack } from 'src/components-v2/Layout';
import { ContentfulTemplates, FormTypes } from 'src/lib/constants';
import { Typography } from 'src/components-v2/DataDisplay';
import { ImageShape, ImageEntryFields } from 'src/lib/ts-types';

interface CtaFields {
  text: string;
  type: string;
  url: string;
}

interface Cta {
  fields: CtaFields;
  sys: Sys;
}

export interface FormFields {
  description: string;
  marketoId: number;
  disclaimer: RichTextContent;
  submitButton: Cta;
  primaryHeading: string;
  secondaryHeading?: string;
  includeJobTitle?: boolean;
  includePhoneField?: boolean;
  includeCommentsField?: boolean;
  includePxFieldset?: boolean;
}

export interface Form {
  fields: FormFields;
  sys: Sys;
}

export interface FormCtaCmsData {
  darkMode: boolean;
  facePile: boolean;
  form: Form;
  primaryHeading: string;
  secondaryHeading?: string;
  image: ImageEntryFields;
  isBodyForm?: boolean;
  disclaimer: RichTextContent;
}

interface FormCtaComponentProps {
  'action-text': string;
  disclaimer: string | RichTextContent;
  image: ImageShape;
  'include-phone': boolean;
  includeJobTitle: boolean;
  includeCommentsField: boolean;
  includePxFieldset: boolean;
  isDarkMode: boolean;
  'marketo-key': number;
  subtext: string;
  'subtext-class': string;
  title: string;
  'with-subtext': boolean;
  includeCompany: boolean;
  formData: {
    'marketo-key': number;
    includeCompany: boolean;
    shouldForward?: boolean;
  };
  marketoFormBtnUrl?: string;
  formType?: FormTypes;
}

const legacyCtaFormAdapter = ({
  darkMode,
  facePile,
  form,
  primaryHeading,
  secondaryHeading = '',
  image,
  isBodyForm = false,
}: FormCtaCmsData): FormCtaComponentProps => {
  const derivedImageProp = {
    // face pile image is predefined, we only look to the CMS
    // to determine whether we should return it or not
    src: facePile
      ? '/assets/img/customer-stories/cta-plaid-employee-row@2x.png'
      : '',
    alt: '',
    width: facePile ? 167 : null,
  };

  const imageProp = image
    ? {
        src: image.fields?.file?.url,
        alt: image.fields?.description,
      }
    : derivedImageProp
    ? derivedImageProp
    : null;

  return {
    'action-text': form?.fields?.submitButton?.fields?.text,
    disclaimer: _get(form, 'fields.disclaimer', ''),
    image: imageProp,
    includeJobTitle: !!form?.fields?.includeJobTitle,
    'include-phone': form?.fields?.includePhoneField ?? !isBodyForm,
    includeCommentsField: !!form?.fields?.includeCommentsField,
    includePxFieldset: !!form?.fields?.includePxFieldset,
    isDarkMode: darkMode,
    'marketo-key': form?.fields?.marketoId,
    subtext: secondaryHeading,
    'subtext-class': 'h4 regular',
    title: primaryHeading,
    'with-subtext': secondaryHeading.length > 0,
    includeCompany: true,
    formData: {
      'marketo-key': form?.fields?.marketoId,
      includeCompany: true,
    },
    marketoFormBtnUrl: form?.fields?.submitButton?.fields?.url,
    formType: FormTypes.CMS,
  };
};

const newCtaFormAdapter = (fields) => {
  const pageTemplateFormCtaAdapterData = {
    ...legacyCtaFormAdapter(fields),
    isNewGrid: true,
    shouldForward: true,
    hasOnPagePostSubmitContent: false,
    postSubmitContent: (
      <Stack justifyContent={'center'} sx={{ height: '100%' }}>
        <Typography variant='h5' sx={{ textAlign: 'center' }}>
          Thanks!
        </Typography>
        <Typography variant='p2' sx={{ textAlign: 'center' }}>
          Your message has been recorded and a Plaid team member will be in
          touch soon.
        </Typography>
      </Stack>
    ),
  };
  return pageTemplateFormCtaAdapterData;
};

const leadFormAdapter = (fields) => {
  const pageTemplateLeadFormAdapterData = {
    ...newCtaFormAdapter(fields),
    body: fields?.body,
    backgroundColor: fields?.backgroundColor || 'black1000',
    backgroundImageColor: fields?.backgroundImageColor?.fields?.color,
    formTitle: fields?.title,
    backgroundImage: fields?.backgroundImage?.fields,
    backgroundMono: fields?.backgroundMono || false,
  };
  return pageTemplateLeadFormAdapterData;
};

const heroFormAdapter = (fields) => {
  return { ...newCtaFormAdapter(fields) };
};

export const formCtaAdapter = ({ fields, template }) => {
  switch (template) {
    case ContentfulTemplates.PAGE:
      return newCtaFormAdapter(fields);
    case 'Lead Form':
      return leadFormAdapter(fields);
    case 'Hero Form':
      return heroFormAdapter(fields);
    default:
      return legacyCtaFormAdapter(fields);
  }
};
