import React from 'react';
import { LinkCarousel } from 'src/sections/LinkCarousel';
import { RichTextSwitch } from 'src/containers';
import { getHrefFromRichText } from 'src/lib/utils';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';
import CmsLeftSectionalHeaderContent from 'src/sections/Header/CmsSectionalHeader/CmsLeftSectionalHeaderContent';
import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import ThemeProvider from 'threads5/themes/theme-provider';
import { CmsSectionalHeader } from 'src/sections/Header';

const CmsLinkCarousel = ({
  header,
  mode,
  backgroundColor = 'black',
  items = [],
  styles = {},
  sys,
}) => {
  const bgColor = backgroundColor || 'black';
  const cmsMode = useCmsMode({
    mode: mode,
    backgroundColor: bgColor,
  });
  const componentTheme = useComponentTheme(cmsMode);
  const background = componentTheme.palette[bgColor] || bgColor;

  // always use a left aligned + simplified header when there are > 3 items
  // this is to accomodate the navigation buttons being moved inline
  return (
    <ThemeProvider theme={componentTheme}>
      <LinkCarousel
        sx={{ background, ...styles }}
        slides={getSlides(items)}
        heading={
          items.length > 3 ? (
            <CmsLeftSectionalHeaderContent
              sys={header?.sys}
              fields={header?.fields}
            />
          ) : (
            <CmsSectionalHeader sys={header?.sys} fields={header?.fields} />
          )
        }
        sys={sys}
      />
    </ThemeProvider>
  );
};

export default CmsLinkCarousel;

export const getSlides = (items) => {
  return items.map((item) => {
    return {
      href: getHrefFromRichText(item?.fields?.body?.content),
      sx: item?.fields?.styles,
      content: (
        <>
          <CmsMediaSwitch
            asset={item?.fields?.asset?.fields}
            width={400}
            height={224}
            queryString={{
              xs: '?fm=webp&w=708&q=60',
              sm: '?fm=webp&w=1120&q=60',
              md: '?fm=webp&w=728&q=60',
              lg: '?fm=webp&w=948&q=60',
            }}
            sys={item?.fields?.asset?.sys}
          />
          <RichTextSwitch
            sys={item?.sys}
            content={{
              ...item?.fields?.body,
              content: item?.fields?.body?.content,
            }}
            nodes={{
              p: {
                variant: 'h5',
                component: 'p',
                fieldId: 'body',
              },
            }}
          />
        </>
      ),
    };
  });
};
