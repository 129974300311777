import { CmsThemes } from 'src/lib/constants';

export const timelineMainColumnProps = {
  [CmsThemes.WEB2]: {
    lg: 7,
    mdOffset: 1,
    md: 8,
    xsOffset: 1,
    xs: 22,
  },
  [CmsThemes.WEB3]: {
    lgOffset: 2,
    lg: 9,
    mdOffset: 1,
    md: 10,
    xsOffset: 1,
    xs: 22,
  },
};

export const timelineItemRichTextNodes = {
  [CmsThemes.WEB2]: {
    h3: {
      variant: 'h6',
      component: 'h3',
      fieldId: 'body',
    },
    p: {
      variant: 'p',
      fieldId: 'body',
    },
  },
  [CmsThemes.WEB3]: {
    h3: {
      variant: 'h2',
      component: 'h3',
      fieldId: 'body',
    },
    p: {
      variant: 'p',
      fieldId: 'body',
    },
  },
};
