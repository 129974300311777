import React from 'react';
import styles from './Breadcrumb.module.scss';
import cx from 'classnames';

const insertSeparators = (items, separator) => {
  return items.reduce((acc, current, index) => {
    // we don't append a separator to last item
    if (index < items.length - 1) {
      acc = acc.concat(
        current,
        <li aria-hidden key={`separator-${index}`}>
          {separator}
        </li>,
      );
    } else {
      acc.push(current);
    }
    return acc;
  }, []);
};

const Breadcrumb = ({ children, separator = ' > ' }) => {
  return (
    <nav
      aria-label='breadcrumb'
      className={cx(styles.breadcrumb, 'hide-for-aeo')}
    >
      <ol>
        {insertSeparators(
          React.Children.toArray(children).map((child, index) => {
            return <li key={`child-${index}`}>{child}</li>;
          }),
          separator,
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
