import * as React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { Player } from '@lottiefiles/react-lottie-player';
import { isProduction } from 'src/lib/constants';
import { animated, easings, useSprings } from '@react-spring/web';
import { Image, AnimatedVideo } from 'src/components-v2/Media';
import { Stack, Box } from 'src/components-v2/Layout';
import { Items, Asset } from './Timeline.types';

const AnimatedStack = animated(Stack);

interface ITimelineSwitchProps {
  assets: Asset[];
  asset: Items;
  index: number;
  sx?: SxProps<Theme>;
}

const TimelineSwitch: React.FunctionComponent<ITimelineSwitchProps> = ({
  asset: media,
  assets,
  index,
  sx,
}) => {
  const { asset } = media;
  const inspectorProps = useContentfulInspectorMode({
    entryId: asset?.sys?.id,
  });
  const [springs] = useSprings(
    assets.length,
    (index) => {
      return {
        from: {
          opacity: 0,
          zIndex: assets.length - index,
        },
        to: {
          opacity: assets[index].isVisible ? 1 : 0,
          zIndex: assets[index].isVisible
            ? assets.length + 1
            : assets.length - index,
        },
        config: {
          duration: 200,
          easing: easings.easeInQuad,
        },
      };
    },
    [assets],
  );
  const assetWrapperStyles = {
    gridRowStart: 1,
    gridColumnStart: 1,
    position: 'relative',
    backgroundColor:
      // @ts-ignore
      sx?.backgroundColor || 'transparent',
  };
  switch (asset.type) {
    case 'image':
      return (
        <AnimatedStack
          style={springs[index]}
          key={`image-${index}`}
          sx={assetWrapperStyles}
          justifyContent={'center'}
          {...inspectorProps({
            fieldId: 'largeImage',
          })}
        >
          <Image
            alt={asset?.alt}
            height={asset?.height}
            width={asset?.width}
            src={asset?.src}
            sources={asset?.sources}
            isLazy={false}
            sx={{
              display: 'block',
              margin: '0 auto',
              ...asset?.styles,
            }}
          />
        </AnimatedStack>
      );
    case 'video':
      return (
        <AnimatedStack
          style={springs[index]}
          key={`video-${index}`}
          sx={assetWrapperStyles}
          {...inspectorProps({
            fieldId: 'video',
          })}
        >
          <AnimatedVideo
            height='100%'
            isLazy={false}
            sources={asset?.sources}
            width='100%'
            sx={asset?.styles}
          />
        </AnimatedStack>
      );
    case 'lottie':
      return (
        <Stack
          key={`lottie-${index}`}
          sx={{ ...assetWrapperStyles }}
          {...inspectorProps({
            fieldId: 'lottie',
          })}
        >
          <Box sx={{ ...asset?.styles }}>
            <Player
              src={asset?.src}
              autoplay={true}
              loop={true}
              style={{
                width: '100%',
                height: '100%',
                margin: '0 auto',
              }}
            />
          </Box>
        </Stack>
      );
    default:
      if (!isProduction) {
        return <p key={`error-${index}`}>hmm something went wrong</p>;
      } else {
        return null;
      }
  }
};

export default TimelineSwitch;
