import React, { FC } from 'react';
import styles from './WelcomeBanner.module.scss';

interface BlogWelcomeBannerProps {
  title: string;
}

const BlogWelcomeBanner: FC<BlogWelcomeBannerProps> = ({ title }) => {
  return (
    <div className={styles.wrapper}>
      <div className='nav-spacer' />

      <h1
        className={styles.header}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </div>
  );
};

export default BlogWelcomeBanner;
