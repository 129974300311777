import T5IconButton from 'threads5/IconButton';
import { useIsDarkMode } from 'src/hooks/useIsDarkMode';

// TODO: refactor to use styled HOC
const IconButton = ({ children, icon, ...rest }) => {
  const isDarkMode = useIsDarkMode();
  const id = isDarkMode ? 'dark-icon-btn' : 'light-icon-btn';

  return (
    <T5IconButton
      icon={icon}
      sx={{
        borderRadius: '100% !important',
        width: { xs: '48px', lg: '54px' },
        height: { xs: '48px', lg: '54px' },
        background: (theme) => {
          return theme.palette['web3/component/icon-button/background-resting'];
        },
        border: '2px solid transparent',
        transition: 'all 0.1s ease-in-out',
        svg: {
          width: { xs: '24px', lg: '32px' },
          height: { xs: '24px', lg: '32px' },
          transition: 'all 0.1s ease-in-out',
          path: {
            fill: `url(#${id})`,
            transition: 'all 0.1s ease-in-out',
          },
        },
        i: {
          width: { xs: '24px', lg: '32px' },
          height: { xs: '24px', lg: '32px' },
        },
        ':hover, :focus-visible': {
          borderWidth: '2px',
        },
        ':hover:not(:disabled)': {
          borderColor: 'transparent',
        },
        '@media (hover: hover)': {
          ':hover': {
            background: (theme) => {
              return theme.palette[
                'web3/component/icon-button/background-interacted'
              ];
            },
            boxShadow: (theme) => {
              return theme.palette['web3/component/icon-button/box-shadow'];
            },
            transform: 'scale(1.05)',
            svg: {
              path: {
                fill: (theme) => {
                  return theme.palette['web3/component/icon-button/svg-fill'];
                },
              },
            },
          },
          ':active': {
            boxShadow: '0px 0px 14px transparent',
            transform: 'scale(1)',
          },
        },
        '&.Mui-disabled': {
          borderWidth: '2px',
          svg: {
            color: 'web3/component/icon-button/border-disabled',
            path: {
              fill: (theme) => {
                return theme.palette[
                  'web3/component/icon-button/border-disabled'
                ];
              },
            },
          },
          background: 'transparent',
        },
      }}
      {...rest}
    >
      <>
        <svg
          width='32'
          height='32'
          viewBox='0 0 40 40'
          xmlns='http://www.w3.org/2000/svg'
        >
          <defs>
            <linearGradient
              id={id}
              x1='30.9473'
              y1='5.45629'
              x2='15.8233'
              y2='35.1678'
              gradientUnits='userSpaceOnUse'
            >
              <stop stopColor={!isDarkMode ? '#8358EF' : '#42F0CD'} />
              <stop
                offset='1'
                stopColor={!isDarkMode ? '#156EF5' : '#0DADF2'}
              />
            </linearGradient>
          </defs>
          <path d='M10 10 H 30 V 30 H 10 Z' />
        </svg>
        {children}
      </>
    </T5IconButton>
  );
};

export default IconButton;
