import { CmsThemes } from 'src/lib/constants';
import { getBorderStyles } from './ItemUtils';

export const accordionListItemStyles = ({
  cmsTheme,
  activeIndex,
  index,
  isDarkBackground,
}) => {
  return (
    {
      [CmsThemes.WEB2]: {
        ...getBorderStyles(activeIndex, index, isDarkBackground),
      },
      [CmsThemes.WEB3]: {
        borderTop: 1,
        borderTopColor: (theme) => {
          return theme.palette['graphic/contrast-medium'];
        },
      },
    }[cmsTheme] || {}
  );
};

export const accordionItemStyles = ({
  cmsTheme,
  isActive,
  isDarkBackground,
}) => {
  return (
    {
      [CmsThemes.WEB2]: {
        '& h4': {
          color: isActive
            ? isDarkBackground
              ? 'white'
              : 'black'
            : isDarkBackground
            ? 'black800'
            : 'black600',
        },
      },
      [CmsThemes.WEB3]: {
        '& h4': {
          color: (theme) => {
            return isActive
              ? theme.palette['web3/text/contrast-max']
              : theme.palette['text/contrast-min'];
          },
        },
      },
    }[cmsTheme] || {}
  );
};

export const accordionIconStyles = ({
  cmsTheme,
  isActive,
  isDarkBackground,
}) => {
  return (
    {
      [CmsThemes.WEB2]: {
        color: isActive
          ? isDarkBackground
            ? 'white'
            : 'black'
          : isDarkBackground
          ? 'black800'
          : 'black600',
      },
      [CmsThemes.WEB3]: {
        '--icon-fill': (theme) => {
          return (
            theme.palette[isDarkBackground ? 'base/white' : 'base/sky/700'] +
            ' !important'
          );
        },
        flexShrink: '0',
      },
    }[cmsTheme] || {}
  );
};
