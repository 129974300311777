import { CmsThemes } from 'src/lib/constants';

export const ctaBannerStackStyles = {
  [CmsThemes.WEB2]: {
    padding: {
      xs: '2.4rem 1.4rem',
      sm: '4.0rem 5.4rem',
      lg: '6.4rem 5.6rem',
    },
  },
  [CmsThemes.WEB3]: {
    padding: {
      xs: '32px',
      sm: '40px 56px',
      lg: '64px 56px',
    },
  },
};
