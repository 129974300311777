import React, { ReactNode } from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Sys } from 'contentful';
import { default as ReactMarquee } from 'react-fast-marquee';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { Image } from 'src/components-v2/Media';
import { Stack, Box } from 'src/components-v2/Layout';
import { S } from 'src/components-v2/StyledElements/StyledElements';
import { useDeviceSize } from 'src/hooks';
import { headingStyles, dividerStyles, borderStyles } from './Logo.styles';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';

interface ILogo {
  src: string;
  alt: string;
  width: number;
  height: number;
}
interface ILogos {
  heading?: ReactNode;
  logos: Array<ILogo>;
  sx?: SxProps<Theme>;
  sys?: Sys;
}

const Logos: React.FC<ILogos> = ({ heading, logos, sx, sys }) => {
  const hasThreeLogos = logos.length === 3;
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  const themeVersion = useCmsTheme();

  return (
    <Stack
      className='cms-logos-container'
      {...inspectorProps({ fieldId: 'internalTitle' })}
      component='section'
      sx={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        ...(borderStyles[themeVersion] || {}),
        pl: heading
          ? {
              xs: '0.8rem',
              sm: '7.2rem',
              md: hasThreeLogos ? '0' : '7.2rem',
              lg: hasThreeLogos ? '0' : '18rem',
            }
          : '0',
        height: { xs: '80px', lg: '120px' },
        '& h2': {
          ...(headingStyles[themeVersion] || {}),
          width: { xs: '105px', md: '135px' },
          mr: { xs: '0.4rem', sm: '0.8rem', md: '1.7rem' },
        },
        '& h2:nth-last-of-type(1):empty': {
          display: 'none',
        },
        // we update React Marquee styles to make content horizontally centered on >=1024px when it is 3 logos
        '& .marquee-container': {
          width: { md: hasThreeLogos ? 'auto' : '100%' },
          '& .marquee:nth-child(2)': {
            // this will always be 2nd child
            display: { md: hasThreeLogos ? 'none' : 'inline-flex' },
          },
        },
        ...sx,
      }}
    >
      {heading && (
        <>
          {heading}
          <S.Hr
            sx={{
              borderRight: 1,
              ...(dividerStyles[themeVersion] || {}),
              height: { xs: '40px', md: '70px' },
              display: { md: hasThreeLogos ? 'none' : 'block' },
            }}
          />
        </>
      )}
      <LogoMarquee logos={logos} sys={sys} />
    </Stack>
  );
};

export default Logos;

const LOGO_DESKTOP_IMAGE = '?fm=webp&w=370&q=60';
const LOGO_MOBILE_IMAGE = '?fm=webp&w=278&q=60';

const LogoMarquee = ({ logos, sys }) => {
  const device = useDeviceSize();
  const hasThreeLogos = logos.length === 3;
  const items = hasThreeLogos
    ? device.isLarge
      ? [...logos]
      : [...logos, ...logos]
    : logos.length > 3 && logos.length < 8
    ? [...logos, ...logos, ...logos]
    : [...logos, ...logos];
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  return (
    <ReactMarquee
      play={hasThreeLogos && device.isLarge ? false : true}
      pauseOnHover={true}
      speed={30}
      gradientWidth={0}
      gradientColor='#e8e8e8'
    >
      <Stack
        component='ul'
        flexDirection='row'
        alignItems='center'
        sx={{
          listStyleType: 'none',
          m: '0',
        }}
      >
        {items.map((logo, index) => {
          return (
            <Box
              key={`logo-$${index}`}
              component='li'
              sx={{
                px: { xs: '3.6rem', sm: '5.6rem', lg: '7.6rem' },
              }}
            >
              <Image
                {...inspectorProps({ fieldId: 'logos' })}
                alt={logo.alt}
                src={logo.src + LOGO_DESKTOP_IMAGE}
                width={logo.width || 185}
                height={logo.height || 38}
                sx={{
                  width: {
                    xs: logo.width * 0.75,
                    lg: logo.width,
                  },
                }}
                isLazy={logo.isLazy}
                sources={[
                  {
                    media: '(max-width: 639px)',
                    srcSet: `${logo.src + LOGO_MOBILE_IMAGE}`,
                  },
                  {
                    media: '(min-width: 640px)',
                    srcSet: `${logo.src + LOGO_DESKTOP_IMAGE}`,
                  },
                ]}
              />
            </Box>
          );
        })}
      </Stack>
    </ReactMarquee>
  );
};
