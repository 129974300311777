import * as React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Box, Stack } from 'src/components-v2/Layout';
import { MediaSwitch } from 'src/components-v2/Media';
import Bullet from './Bullet';
import { ITimelineItemProps } from './Timeline.types';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import { CmsThemes } from 'src/lib/constants';

const TimelineItem: React.FunctionComponent<ITimelineItemProps> = ({
  index,
  item,
  items,
  refs,
  timelineRefBounds,
}) => {
  const inspectorProps = useContentfulInspectorMode({ entryId: item?.sys?.id });
  const cmsTheme = useCmsTheme();
  const isV2 = cmsTheme === CmsThemes.WEB2;

  return (
    <Stack
      key={`step-${index}`}
      sx={{
        height: {
          md:
            index === items.length - 1
              ? `calc((100vh - ${refs.current[index]?.clientHeight}px) / 2 + ${refs.current[index]?.clientHeight}px)`
              : '100vh',
          xs: 'auto',
        },
        justifyContent: index === items.length - 1 ? 'flex-end' : 'center',
        ...items?.[index]?.sx,
      }}
    >
      <Box
        id={item.id}
        ref={(el) => {
          return (refs.current[index] = el as HTMLDivElement);
        }}
        {...inspectorProps({
          fieldId: 'internalTitle',
        })}
      >
        {isV2 && <Bullet bounds={timelineRefBounds} />}
        {item.content}
        <MediaSwitch
          key={`mobile-asset-${index}`}
          asset={{ ...items[index].asset }}
        />
      </Box>
    </Stack>
  );
};

export default TimelineItem;
