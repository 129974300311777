import React, { useState } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';

// components
import US from 'plaid-threads/Flags/US';
import GB from 'plaid-threads/Flags/GB';
import EU from 'plaid-threads/Flags/EU';
import { Dropdown, DropdownLink } from 'src/components/Inputs/Dropdown';
import Checkmark from 'plaid-threads/Icons/Checkmark';
import Pin from 'plaid-threads/Icons/Pin';

// styles
import styles from './LocaleSwitch.module.scss';

// dependencies
import { useUserLocale } from 'src/hooks';
import { Locales } from 'src/lib/constants';
import { usePageLocale } from 'src/contexts';
import { fetchLocalizedEquivalentPage } from 'src/lib/utils';

const localeOptions = [
  {
    label: 'United States',
    value: Locales.EN_US,
    icon: <US />,
  },
  {
    label: 'United Kingdom',
    value: Locales.EN_GB,
    icon: <GB />,
  },
  {
    label: 'European Union',
    value: Locales.EN_EU,
    icon: <EU />,
  },
];

const localeMap = new Map();
localeOptions.forEach((option) => {
  localeMap.set(option.value, option.label);
});

interface Props {
  lightMode?: boolean;
}

const LocaleSwitch: React.FC<Props> = ({ lightMode = false }) => {
  const pageLocale = usePageLocale();
  const { asPath, basePath } = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const UserLocale = useUserLocale();
  const locale = UserLocale.locale?.localeCode;

  return (
    <div
      className={styles.wrapper + ' hide-for-aeo'}
      data-testid='locale-switch'
    >
      <Pin />
      <Dropdown
        wrapperClassName={cx(styles.localeSwitch, {
          [styles.lightMode]: lightMode,
        })}
        isOpen={isOpen}
        options={localeOptions.map((option) => {
          if (option.label === localeMap.get(locale)) {
            return {
              ...option,
              label: (
                <>
                  <span>{option.label}</span>
                  <Checkmark className={styles.checkmark} />
                </>
              ),
            };
          }
          return option;
        })}
        target={
          <DropdownLink
            onClick={() => {
              return setIsOpen(!isOpen);
            }}
            expanded={isOpen}
            aria-label={`Select locale, ${localeMap.get(locale)}`}
          >
            {localeMap.get(locale) || 'Choose...'}
          </DropdownLink>
        }
        value={localeMap.get(locale)}
        onChange={async (option) => {
          UserLocale.setLocale(option.value);
          const localizedEquivalent = await fetchLocalizedEquivalentPage({
            asPath,
            userLocale: option.value,
            pageLocale,
            basePath,
          });
          setIsOpen(false);
          window.location.href = localizedEquivalent;
        }}
        onEscape={() => {
          return setIsOpen(false);
        }}
      />
    </div>
  );
};

export default LocaleSwitch;
