import styled from 'threads5/themes/styled';
import { Button as ButtonUnstyled } from '@mui/base/Button';
import { variants } from './Btn.types';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import { CmsThemes } from 'src/lib/constants';

type Props = {
  variant: variants;
  isInverted: boolean;
};

const StyledButton = styled(ButtonUnstyled)<Props>(
  ({ theme, variant, isInverted }) => {
    const cmsTheme = useCmsTheme();

    // color inversion
    const isBlackOrWhite =
      (isInverted ? theme.palette.black : theme.palette.white) + ' !important';
    const isWhiteOrBlack =
      (isInverted ? theme.palette.white : theme.palette.black) + ' !important';

    const focusBaseStyles = {
      // outline: `solid 1px -webkit-focus-ring-color`,
      // outlineOffset: '3px',
    };

    const sharedButtonThemes = {
      [CmsThemes.WEB2]: {
        padding: '0 27px',
        letterSpacing: '-0.75px',
        color: isBlackOrWhite,
        borderColor: isWhiteOrBlack,
      },
      [CmsThemes.WEB3]: {
        padding: '0 24px',
        letterSpacing: '-0.3px',
        background: `${theme.palette['web3/component/button/primary/background-resting']} !important`,
        backgroundBlendMode: 'luminosity',
        transformOrigin: 'center',
        color: `${theme.palette['web3/component/button/text/color']} !important`,
        borderColor: `${theme.palette['graphic/interactive/neutral/resting/high']} !important`,
        '&:hover, :focus': {
          background: `${theme.palette['web3/component/button/primary/background-hovered']} !important`,
          border: 'solid 1px',
          borderColor: `${theme.palette['background/contrast-high']} !important`,
          color: `${theme.palette['web3/component/button/text/color']} !important`,
          ...focusBaseStyles,
        },
        '&:active': {
          transform: 'scale(0.99)',
        },
        '&:focus-visible': {
          ...focusBaseStyles,
        },
        [theme.breakpoints.up('xs')]: {
          fontSize: '1.8rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '2.0rem',
        },
      },
    };

    // animation settings
    const transitionIn = '200ms';
    const transitionOut = '350ms';
    const transitionTimingFunction = 'cubic-bezier(0.23, 1.2, 0.32, 1)';

    const sharedStyles = {
      alignItems: 'center',
      background: isWhiteOrBlack,
      border: 'solid 1px',
      borderRadius: '240px !important',
      display: 'inline-flex',
      fontFamily: theme.typography.sans,
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontSize: '2.0rem',
      fontStyle: 'normal',
      fontWeight: 600,
      height: '48px',
      justifyContent: 'center',
      lineHeight: '130%',
      transition:
        'background-color, color, border-color, background, transform, border, box-shadow',
      transitionDuration: transitionOut,
      transitionTimingFunction,
      '& svg': {
        transition: 'color',
        transitionDuration: transitionOut,
        transitionTimingFunction,
        '&:hover': {
          transition: 'color',
          transitionDuration: transitionIn,
        },
      },
      '&:hover, :focus': {
        background: isBlackOrWhite,
        borderColor: isWhiteOrBlack,
        color: isWhiteOrBlack,
        transitionDuration: transitionIn,
        '& svg': {
          color: isWhiteOrBlack,
        },
      },
      '&:active': {
        background: isWhiteOrBlack,
        borderColor: isBlackOrWhite,
        color: isBlackOrWhite,
        '& svg': {
          color: isBlackOrWhite,
        },
      },
      [theme.breakpoints.up('xl')]: {
        height: '56px',
        lineHeight: '150%',
      },
      ...sharedButtonThemes[cmsTheme],
    };

    const secondaryThemeStyles = {
      [CmsThemes.WEB2]: {
        color: isWhiteOrBlack,
      },
      [CmsThemes.WEB3]: {
        background: `transparent`,
        boxShadow: `0px 0px 5px 1px transparent`,
        borderColor: `${theme.palette['graphic/interactive/neutral/resting/high']} !important`,
        color: `${theme.palette['graphic/interactive/neutral/resting/high']} !important`,
        '&:hover, :focus': {
          boxShadow: `${theme.palette['web3/component/button/secondary/box-shadow']}`,
          border: `1px solid`,
          borderColor: `${theme.palette['web3/component/button/secondary/border-resting']} !important`,
        },
        '&:active': {
          transform: 'scale(0.99)',
          boxShadow: `0px 0px 5px 1px transparent`,
          borderColor: `${theme.palette['web3/component/button/secondary/border-resting']} !important`,
        },
        '&:focus-visible': {
          ...focusBaseStyles,
        },
      },
    };

    const secondaryStyles = {
      ...sharedStyles,
      background: isBlackOrWhite,
      borderColor: isWhiteOrBlack,
      '&:hover, :focus': {
        background: isWhiteOrBlack,
        color: isBlackOrWhite,
      },
      '&:active': {
        background: isBlackOrWhite,
        borderColor: isWhiteOrBlack,
        color: isWhiteOrBlack,
      },
      ...secondaryThemeStyles[cmsTheme],
    };

    const tertiaryThemeStyles = {
      [CmsThemes.WEB2]: {
        fontSize: '1.6rem',
        paddingRight: '36px',
        color: isWhiteOrBlack,
      },
      [CmsThemes.WEB3]: {
        paddingRight: theme.spacing(2),
        color: `${theme.palette['graphic/interactive/neutral/resting/high']} !important`,
        '& svg': {
          color:
            theme.palette['web3/component/button/tertiary/graphic-resting'],
          transition: 'color 200ms ease-in-out',
        },
        '&:focus-visible': {
          ...focusBaseStyles,
        },
        '& .movingIcon': {
          boxShadow: `0px 0px 5px 0px transparent`,
          transition: 'box-shadow 200ms ease-in-out',
        },
        '&:hover, :focus': {
          background: 'transparent !important',
          '& svg': {
            color:
              theme.palette[
                'web3/component/button/tertiary/graphic-interacted'
              ],
          },
          '& .movingIcon': {
            boxShadow:
              theme.palette['web3/component/button/tertiary/box-shadow'],
          },
        },
        '&:active': {
          borderColor: 'transparent !important',
          '& .movingIcon': {
            boxShadow: `0px 0px 5px 0px transparent`,
          },
        },
        [theme.breakpoints.up('xs')]: {
          fontSize: '1.8rem',
        },
        [theme.breakpoints.up('md')]: {
          fontSize: '2.0rem',
        },
      },
    };

    const tertiaryStyles = {
      alignItems: 'center',
      border: 'solid 1px',
      borderColor: theme.palette.transparent,
      borderRadius: '240px',
      display: 'inline-flex',
      fontFamily: theme.typography.sans,
      fontWeight: 600,
      height: '40px',
      letterSpacing: '-0.75px',
      transition: 'background-color, color, border-color',
      transitionDuration: '100ms',
      transitionTimingFunction: 'ease-in-out',
      '& a, button': {
        color: isWhiteOrBlack,
        lineHeight: '140%',
        verticalAlign: 'text-bottom',
      },
      '& svg': {
        color: 'black',
      },
      '&:hover, :focus': {
        backgroundColor: isWhiteOrBlack,
        border: 'solid 1px',
        borderColor: theme.palette.transparent,
        color: isBlackOrWhite,
        '& a, button': {
          color: isBlackOrWhite,
        },
        '& svg': {
          color: isBlackOrWhite,
        },
      },
      '&:active': {
        backgroundColor: isBlackOrWhite,
        border: 'solid 1px',
        borderColor: isWhiteOrBlack,
        color: isWhiteOrBlack,
        '& a, button': {
          color: isWhiteOrBlack,
          verticalAlign: 'text-bottom',
        },
        '& svg': {
          color: isWhiteOrBlack,
        },
      },
      '&[type="button"], &[type="submit"]': {
        borderRadius: '240px',
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.8rem',
        lineHeight: '150%',
      },
      ...tertiaryThemeStyles[cmsTheme],
    };

    const variants = {
      primary: {
        ...sharedStyles,
      },
      secondary: {
        ...secondaryStyles,
      },
      tertiary: {
        ...tertiaryStyles,
      },
      // WTF is this?
      quinary: {
        fontWeight: 600,
        textDecoration: 'none !important',
        textDecorationColor: 'transparent !important',
        color: 'var(--black800) !important',
        transition: 'background-color, color, border-color',
        transitionDuration: transitionOut,
        transitionTimingFunction,
        '& ~ a': {
          textDecoration: 'none !important',
          textDecorationColor: 'transparent !important',
          color: 'var(--black800) !important',
        },
        '& svg': {
          padding: '6px',
          color: 'inherit',
          borderRadius: '50%',
          overflow: 'hidden',
          transition: 'inherit',
        },
        '&:hover, &:focus, p > &:hover, p > &:focus': {
          color: 'var(--black1000) !important',
          textDecoration: 'none  !important',
          textDecorationColor: 'transparent !important',
          '& ~ a': {
            textDecoration: 'none !important',
            textDecorationColor: 'transparent !important',
          },
          '& svg': {
            backgroundColor: 'white',
            color: 'inherit',
          },
        },
      },
    };

    return variants[variant];
  },
);

export default StyledButton;
