// TODO: refactor this ... all of it
// https://jira.plaid.com/browse/SITE-1926
import React, { useState } from 'react';
import cx from 'classnames';

import NewsBanner from 'src/components/news-banner.jsx';
import ButtonLinksSection from 'src/components/button-links-section';

import { FormSection } from 'src/components/Forms';
import { FormSectionRichDescription } from '../Forms/FormSection/FormSectionRichDescription';
import { Button } from 'src/components/Inputs';
import Paywall from 'src/components/Surfaces/Paywall';
import Image from 'src/components/Image';
import { usePaywallProvider } from 'src/components/Surfaces';

// helpers
import { ConditionalWrapper, AnimatedGroup } from '../helpers';
import animationStyles from 'src/hooks/useAnimations/useAnimations.module.scss';
import { optimizeImage } from 'src/lib/image-helpers';

const CTAButtonSection = () => {
  return (
    <div className='button-group button-group-justified'>
      <Button
        className='button-cta signup-attach-utm'
        href='//dashboard.plaid.com/signup'
      >
        Sign up
      </Button>
      <Button
        className='button-cta'
        href='//dashboard.plaid.com/contact'
        secondary
      >
        Contact Sales
      </Button>
    </div>
  );
};

const FormSectionHeading = (props) => {
  const DynamicHeading = props['header-size'];
  return (
    <>
      {props['image-based'] && (
        <>
          {props.label && (
            <h6 className='page-header-label'>{props['label-text']}</h6>
          )}
          <DynamicHeading
            className='page-header-header'
            dangerouslySetInnerHTML={{
              __html: props.header,
            }}
          />
          {props.subheader &&
            (props['subheader-p'] ? (
              <p className='regular page-header-subheader'>{props.subheader}</p>
            ) : (
              <h4 className='regular page-header-subheader'>
                {props.subheader}
              </h4>
            ))}
        </>
      )}
    </>
  );
};

const PostSubmitContent = (props) => {
  const DynamicHeading = props['header-size'];
  return (
    <div className='cell small-12 medium-6 post-submit-content'>
      {props['post-submit-label'] && (
        <h6 className='page-header-label'>{props['post-submit-label']}</h6>
      )}
      {props['post-submit-header'] && (
        <DynamicHeading className='page-header-header'>
          {props['post-submit-header']}
        </DynamicHeading>
      )}
      {props['post-submit-subheader'] &&
        (props['post-submit-subheader-p'] ? (
          <p className='regular page-header-subheader'>
            {props['post-submit-subheader']}
          </p>
        ) : (
          <h4 className='regular page-header-subheader'>
            {props['post-submit-subheader']}
          </h4>
        ))}
      {props['post-submit-action-text'] && (
        <Button
          className='button-cta mb1'
          href={props['post-submit-action-link']}
          fullWidth
          secondary
        >
          {props['post-submit-action-text']}
        </Button>
      )}
      {props.postSubmitContent && props.postSubmitContent}
    </div>
  );
};

const Video = (props) => {
  return (
    <>
      <video
        className='page-header-video show-for-medium'
        autoPlay
        loop
        muted
        width={props['video-width']}
        height={props['video-height']}
        poster={optimizeImage(
          props['video-poster-image'] || props['video-fallback-image'],
        )}
      >
        {props['video-formats'].map((item, i) => {
          return (
            <source
              src={item.url}
              type={`video/${item.type}`}
              key={`video-format-${i}`}
            />
          );
        })}
      </video>
      <Image
        className='hide-for-medium'
        src={props['video-fallback-image']}
        alt=''
        isLazy={false}
      />
    </>
  );
};

const PageHeader = (props) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const shouldRenderPreSubmitContent =
    props.hasOnPagePostSubmitContent === false || isFormSubmitted === false;
  const shouldRenderPostSubmitContent =
    props.hasOnPagePostSubmitContent && isFormSubmitted;
  const { mediaWidthClasses } = props;
  const hasAnimations =
    props['slideUpContent'] || props['slideUpImage'] || props['popup-logo-url'];
  const shouldShowDisclaimer = props.showDisclaimer && props.disclaimer;
  const { setIsModalOpen, isContentUnlocked, renderOption, hasPaywall } =
    usePaywallProvider();

  return (
    <>
      {props['form-header'] ? (
        <div className='grid-container form-header'>
          <div
            className={`grid-x ${
              !props['oversize-image'] ? 'grid-margin-x' : ''
            } align-justify`}
          >
            {props['flip-horizontal'] ? (
              <>
                {shouldRenderPreSubmitContent && (
                  <div className='cell small-12 medium-6'>
                    {props.type === 'with-cta-buttons' ? (
                      <CTAButtonSection {...props} />
                    ) : (
                      <>
                        <FormSectionHeading {...props} />
                        <FormSectionRichDescription
                          body={props.body}
                          showDescription={true}
                        />

                        <FormSection
                          {...props}
                          setIsFormSubmitted={setIsFormSubmitted}
                          showDescription={false}
                        />
                      </>
                    )}
                  </div>
                )}
                {shouldRenderPostSubmitContent && (
                  <PostSubmitContent {...props} />
                )}
                {props['image-based'] && (
                  <>
                    {props['video'] ? (
                      <div className='cell small-12 small-order-2 medium-6 medium-order-1 large-6'>
                        <div className='page-header-bg'>
                          <Video {...props} />
                        </div>
                      </div>
                    ) : (
                      <div
                        className={cx(
                          'cell',
                          'small-12',
                          'page-header-bg-wrapper',
                          {
                            'medium-6': props['form-image-full-width'],
                            'medium-5 medium-offset-1':
                              !props['form-image-full-width'],
                            'page-header-bg-wrapper--hide-mobile':
                              !props['image-based-mobile'],
                          },
                        )}
                      >
                        <div
                          className={cx('page-header-bg', {
                            'page-header-bg--vertical-center':
                              props['header-image-vert-center'],
                            'page-header-bg--full-width':
                              props['form-image-full-width'],
                          })}
                        >
                          <Image
                            className={cx(props['img-class'])}
                            src={props['header-image']}
                            alt={props.headerImageAlt || ''}
                            isLazy={false}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <div className='cell small-12 medium-5'>
                  <FormSectionHeading {...props} />
                  <FormSectionRichDescription
                    body={props.body}
                    showDescription={true}
                  />
                </div>
                <div className='cell small-12 medium-6 medium-offset-1'>
                  {props.type === 'with-cta-buttons' ? (
                    <CTAButtonSection {...props} />
                  ) : (
                    <>
                      <FormSection
                        {...props}
                        setIsFormSubmitted={setIsFormSubmitted}
                        showDescription={false}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className={cx('page-header', props.classes, {
            'page-header-dark': props.dark,
            'page-header-with-disclaimer': shouldShowDisclaimer,
          })}
          style={{
            ...(props['background-image'] && {
              backgroundImage: `url("${optimizeImage(
                props['background-image'],
              )}")`,
            }),
          }}
        >
          <ConditionalWrapper
            condition={hasAnimations}
            wrapper={(children) => {
              return <AnimatedGroup>{children}</AnimatedGroup>;
            }}
          >
            <div className='grid-container'>
              <div className='grid-x align-justify'>
                <div
                  className={cx(
                    'cell',
                    'small-12',
                    props['content-width-classes'],
                    'page-header-content',
                    {
                      [animationStyles['animated-group__animation']]:
                        props.slideUpContent,
                      [animationStyles[
                        'animated-group__animation--slide-up-16'
                      ]]: props.slideUpContent,
                      [animationStyles[
                        'animated-group__animation--duration-300'
                      ]]: props.slideUpContent,
                    },
                  )}
                >
                  <div
                    className={cx(
                      'grid-x',
                      props['content-alignment'],
                      props['text-alignment'],
                    )}
                  >
                    <div
                      className={cx(
                        'cell',
                        'small-10',
                        'page-header-breakpoint-12',
                        props['content-width'],
                      )}
                    >
                      {props['news-banner'] && (
                        <div className='grid-x show-for-medium'>
                          <div className='cell small-12 medium-7 large-auto news-banner-container'>
                            <NewsBanner {...props} />
                          </div>
                        </div>
                      )}
                      {props.label && (
                        <h6
                          className={cx(
                            'page-header-label',
                            props['label-classes'],
                          )}
                        >
                          {props['label-text']}
                        </h6>
                      )}
                      <h1
                        className={cx(
                          'page-header-header',
                          props['header-class'],
                        )}
                        dangerouslySetInnerHTML={{ __html: props.header }}
                      />
                      {props.subheader &&
                        (props['subheader-p'] ? (
                          <p className='regular page-header-subheader'>
                            {props.subheader}
                          </p>
                        ) : (
                          <p className='regular page-header-subheader h4'>
                            {props.subheader}
                          </p>
                        ))}

                      {shouldShowDisclaimer && (
                        <p className='regular page-header-subheader'>
                          {props.disclaimer}
                        </p>
                      )}
                      {props.buttonLinks && <ButtonLinksSection {...props} />}
                    </div>
                    <div className='cell medium-12 large-6 page-header-breakpoint-12'>
                      {props['inline-cta-buttons'] ? (
                        <div className='button-group grid-x grid-margin-x'>
                          {props['primary-onclick'] && (
                            <Button
                              onClick={props['primary-onclick-function']}
                              className={cx(
                                'cell',
                                'medium-6',
                                'small-12',
                                'button-cta',
                                {
                                  alternate: props.dark,
                                },
                              )}
                            >
                              {props['primary-onclick-text']}
                            </Button>
                          )}
                        </div>
                      ) : (
                        <div className='button-group button-group--margin-bottom'>
                          {(!hasPaywall || isContentUnlocked) && (
                            <>
                              {props['primary-cta'] && (
                                <Button
                                  href={props['primary-cta-link']}
                                  className={cx('signup-attach-utm', {
                                    alternate: props.dark,
                                  })}
                                >
                                  {props['primary-cta-text']}
                                </Button>
                              )}
                              {props['secondary-cta'] && (
                                <Button
                                  href={props['secondary-cta-link']}
                                  className={cx({
                                    alternate: props.dark,
                                  })}
                                  secondary
                                  trackingId={
                                    props?.secondaryCtaTrackingId || ''
                                  }
                                >
                                  {props['secondary-cta-text']}
                                </Button>
                              )}
                            </>
                          )}

                          <Paywall.TargetButton
                            targetButton={props.targetButton}
                            setIsModalOpen={setIsModalOpen}
                            hasPaywall={hasPaywall}
                            isContentUnlocked={isContentUnlocked}
                            renderOption={renderOption}
                            dark={props.dark}
                          />
                        </div>
                      )}
                      {props['tertiary-cta'] && (
                        <p
                          className='page-header-subtext'
                          dangerouslySetInnerHTML={{
                            __html: props['tertiary-cta'],
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`cell small-12 medium-6 ${
                    mediaWidthClasses && mediaWidthClasses
                  } page-header-bg-wrapper ${
                    props['img-has-brushstroke']
                      ? 'page-header-bg-wrapper--negative-bottom'
                      : ''
                  } ${
                    props['no-min-height-header']
                      ? 'page-header-bg-wrapper--hide-mobile'
                      : ''
                  } ${
                    props['image-phone']
                      ? 'center-content center-content--mobile'
                      : ''
                  }`}
                >
                  {props['news-banner'] && <NewsBanner mobile {...props} />}
                  <div
                    className={cx('page-header-bg', {
                      'page-header-bg--phone': props['image-phone'],
                    })}
                  >
                    {props.video && <Video {...props} />}
                    {props.image && (
                      <Image
                        src={props['image-url']}
                        alt={props.imageAltText || props['label-text'] || ''}
                        width={props['image-width']}
                        height={props['image-height']}
                        className={cx(props['image-classes'], {
                          [animationStyles['animated-group__animation']]:
                            props.slideUpImage,
                          [animationStyles[
                            'animated-group__animation--slide-up-40'
                          ]]: props.slideUpImage,
                          [animationStyles[
                            'animated-group__animation--duration-600'
                          ]]: props.slideUpImage,
                        })}
                        isLazy={false}
                      />
                    )}
                    {props['popup-logo-url'] && (
                      <Image
                        src={props['popup-logo-url']}
                        alt={props.iconAltText || props['label-text'] || ''}
                        width={props['image-width']}
                        height={props['image-height']}
                        className={cx(
                          'page-header__popup-logo',
                          animationStyles['animated-group__animation'],
                          animationStyles[
                            'animated-group__animation--slide-up-bounce'
                          ],
                          animationStyles[
                            'animated-group__animation--delay-600'
                          ],
                          {
                            'page-header__popup-logo--phone':
                              props['image-phone'],
                          },
                        )}
                        isLazy={false}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ConditionalWrapper>
        </div>
      )}
    </>
  );
};

export default PageHeader;
