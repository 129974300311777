import { CmsThemes } from 'src/lib/constants';

export const ctaBlockStyles = ({ cmsTheme, isDarkBackground }) => {
  return (
    {
      [CmsThemes.WEB2]: {
        borderTop: 'solid 1px',
        borderColor: isDarkBackground ? 'black900' : 'black400',
      },
      [CmsThemes.WEB3]: {},
    }[cmsTheme] || {}
  );
};
