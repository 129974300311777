import { Box } from 'src/components-v2/Layout';
import { IBtnProps } from './Btn.types';
import { ConditionalWrapper } from 'src/components-v2/Helpers';
import Link from '../Link/Link';
import { useTrack, trackingActions } from 'src/contexts/AnalyticsContext';
import styled from 'threads5/themes/styled';
import { Button as ButtonUnstyled } from '@mui/base/Button';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';

const StyledSecondaryButton = styled(ButtonUnstyled)(({ theme }) => {
  return {
    alignItems: 'center',
    border: 'none',
    display: 'inline-flex',
    gap: 1,
    fontFamily: theme.typography.sans,
    fontWeight: 600,
    height: '48px',
    letterSpacing: '-0.75px',
    lineHeight: '130%',
    color: `${theme.palette['web3/component/button/secondary/text']} !important`,
    position: 'relative',
    padding: '0 24px',
    borderRadius: '100px !important',
    outline: 'none',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.0rem',
    },
    [theme.breakpoints.up('xl')]: {
      height: '56px',
      lineHeight: '150%',
    },
    '&:focus-visible': {
      outlineWidth: '2px',
      outlineColor: theme.palette['web3/component/button/outline'],
      outlineStyle: 'solid',
      outlineOffset: '3px',
    },
    '& .bg-c': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderRadius: 'inherit',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        padding: '2px',
        borderRadius: 'inherit',
        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
        maskComposite: 'exclude',
        background:
          theme.palette['web3/component/button/secondary/resting-border'],
      },
    },
    '& .bg': {
      content: '""',
      position: 'absolute',
      top: '2px',
      left: '2px',
      right: '2px',
      bottom: '2px',
      background: 'transparent',
      borderRadius: 'inherit',
    },
    '& .txt': {
      position: 'relative',
    },
    '&:hover .bg-c, &:active .bg-c': {
      background:
        theme.palette['web3/component/button/secondary/interacted-fill'],
    },
    '&:hover .bg-c': {
      boxShadow: theme.palette['web3/component/button/secondary/box-shadow'],
    },
    '&:active .bg-c, &:active .txt': {
      boxShadow: 'none',
      transition: 'transform 0.1s ease',
      transform: 'scale(0.98)',
    },
    '&:hover .bg-c:before': {
      background:
        theme.palette['web3/component/button/secondary/interacted-border'],
    },
  };
});

const BtnSecondary = ({
  children,
  isInverted,
  trackingId,
  variant,
  sys,
  backgroundColor,
  iconName,
  iconSize,
  defaultGap,
  ...props
}: IBtnProps) => {
  const track = useTrack();
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  const handleClick = (event) => {
    if (trackingId) {
      track({
        type: trackingActions.BUTTON_CLICKED,
        payload: { trackingId, event, currentTarget: event.currentTarget },
      });
    }
    if (props.onClick && !props.href) {
      props.onClick(event);
    }
  };

  return (
    <ConditionalWrapper
      condition={props.href}
      wrapper={(children) => {
        return <Link href={props.href}>{children}</Link>;
      }}
    >
      <StyledSecondaryButton
        {...props}
        onClick={handleClick}
        {...inspectorProps({ fieldId: 'text' })}
      >
        <Box component='span' className='bg-c'>
          <Box component='span' className='bg' />
        </Box>
        <Box component='span' className='txt'>
          {children}
        </Box>
      </StyledSecondaryButton>
    </ConditionalWrapper>
  );
};

export default BtnSecondary;
