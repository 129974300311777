import React from 'react';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import { Box, Stack } from 'src/components-v2/Layout';
import { RichTextSwitch } from 'src/containers';
import { CmsMediaSwitch } from 'src/components-v2/Media';

import cx from 'classnames';
import styles from './CardLinked.module.scss';

interface ICardLinked {
  card: any;
  sx?: SxProps<Theme>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const CardLinked: React.FC<ICardLinked> = ({
  card,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onBlur,
  sx,
}) => {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      className={styles.CardLinked}
    >
      <Stack direction='column' className={styles.CardLinked__inner} sx={sx}>
        <Box
          className={cx(styles.CardLinked__asset)}
          sx={
            card.fields.body && card.fields.body.content.length > 0
              ? {}
              : {
                  border: 'none',
                }
          }
        >
          <CmsMediaSwitch
            asset={card.fields.asset && card.fields.asset.fields}
          />
        </Box>
        {card.fields.body && card.fields.body.content.length > 0 && (
          <Box className={cx(styles.CardLinked__content)}>
            <RichTextSwitch
              sys={card?.sys}
              content={card.fields.body}
              nodes={{
                h3: {
                  variant: 'p',
                  component: 'h3',
                },
                p: {
                  variant: 'p2',
                  fieldId: 'body',
                },
                a: {
                  variant: 'p2',
                },
              }}
            />
          </Box>
        )}
      </Stack>
    </div>
  );
};
