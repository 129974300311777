import React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Spacer as SpacerProps } from './Spacer.types';
import styled from 'threads5/styles/styled';
import cx from 'classnames';

const forbiddenProps = new Set([
  'height',
  'backgroundColor',
  'backgroundImage',
  'sx',
]);
const shouldForwardProp = (prop: string) => {
  return !forbiddenProps.has(prop);
};

const StyledSpacer = styled('div', { shouldForwardProp })<SpacerProps>(
  ({ theme, height = 0, backgroundColor, backgroundImage }) => {
    return {
      backgroundColor: backgroundColor
        ? `${theme.palette[backgroundColor] ?? backgroundColor}`
        : 'transparent',
      ...(backgroundImage?.fields && {
        backgroundImage: `url(${backgroundImage.fields.file?.url}?fm=webp&q=30)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }),
    };
  },
);

const Spacer = React.forwardRef<HTMLDivElement, SpacerProps>(
  (
    { height, backgroundColor, backgroundImage, sx = {}, sys, className },
    ref,
  ) => {
    const inspectorProps = useContentfulInspectorMode({
      entryId: sys?.id,
    });
    return (
      <StyledSpacer
        className={cx('spacer', className)}
        ref={ref}
        data-testid='spacerContainer'
        height={height}
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        {...inspectorProps({ fieldId: 'internalTitle' })}
        sx={{
          height: {
            xs: `${Math.ceil(height * 0.38)}px`,
            sm: `${Math.ceil(height * 0.5)}px`,
            md: `${height}px`,
          },
          ...sx,
        }}
      />
    );
  },
);

export default Spacer;
