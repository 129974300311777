import React from 'react';
import CardsGrid from 'src/sections/Cards/CardsGrid';
import { CmsSectionalHeader } from 'src/sections';
import { RichTextSwitch } from 'src/containers';
import { makeCardGridNodes } from 'src/sections/Cards/CmsCardsSwitch/utils';
import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import ThemeProvider from 'threads5/themes/theme-provider';
import { BackgroundContext } from 'src/contexts';

const CmsCardsGrid = ({ fields, sys }) => {
  const cards = fields?.gridCardsRow2
    ? fields?.gridCardsRow1?.concat(fields?.gridCardsRow2) || []
    : fields?.gridCardsRow1 || [];

  const bgColor = fields?.backgroundColor || 'white';
  const cmsMode = useCmsMode({
    mode: fields?.mode,
    backgroundColor: bgColor,
  });
  const componentTheme = useComponentTheme(cmsMode);
  const background = componentTheme.palette[bgColor] || bgColor;
  const isGradient = fields?.backgroundColor?.includes('gradient');

  return (
    <ThemeProvider theme={componentTheme}>
      <BackgroundContext.Provider value={bgColor}>
        <CardsGrid.Container
          sys={sys}
          sx={{
            background,
            ...fields?.styles,
          }}
          heading={
            <CmsSectionalHeader
              sys={fields?.header?.sys}
              fields={{
                ...fields?.header?.fields,
                backgroundColor: isGradient ? 'transparent' : background,
              }}
            />
          }
          cards={cards?.map((card, index) => {
            return toGridCard(card, index, fields?.backgroundColor, cmsMode);
          })}
        />
      </BackgroundContext.Provider>
    </ThemeProvider>
  );
};

export default CmsCardsGrid;

export const toGridCard = (card, index, backgroundColor, parentMode) => {
  const isFeatured = card?.fields?.appearance === 'Featured';
  const body = isFeatured
    ? card?.fields?.featuredBody
    : card?.fields?.standardBody;

  return (
    <CardsGrid.Card
      className='cms-cards-grid-card'
      sx={{
        ...card?.fields?.styles,
      }}
      mode={card?.fields?.mode || parentMode}
      backgroundColor={card?.fields?.backgroundColor || 'transparent'}
      isFeatured={isFeatured}
      key={`cards-grid-card-${index}`}
    >
      <RichTextSwitch
        sys={card?.sys}
        content={body}
        nodes={makeCardGridNodes(isFeatured)}
      />
    </CardsGrid.Card>
  );
};
