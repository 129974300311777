import React, { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import parsePath from 'src/lib/parse-path';
import CloseIcon from 'plaid-threads/Icons/CloseS1';

// components
import { Link } from 'src/components/Inputs';
import { Alert } from 'src/components/Feedback';

// helpers
import {
  useUserLocale,
  useForeignEquivalentPage,
  useDeviceSize,
} from 'src/hooks';
import { usePageLocale } from 'src/contexts';

// styles
import styles from './ForeignContentNotification.module.scss';
import { NEXT_PUBLIC_PERCY_RUN } from 'src/env';

const ForeignContentNotification: React.FC<unknown> = () => {
  const wrapper = useRef(null);
  const pageLocale = usePageLocale();
  const router = useRouter();
  const parsedPath = parsePath(router.asPath);
  const { locale: userLocale } = useUserLocale();
  const { localizedEquivalent } = useForeignEquivalentPage();
  const [isOpen, setIsOpen] = useState(false);
  const device = useDeviceSize();

  useEffect(() => {
    // since we're using the old cookie API it's best to wait a bit before
    // checking if we should display the foreign content notification
    const timer = setTimeout(() => {
      if (pageLocale !== userLocale?.localeCode) {
        setIsOpen(true);
      }
    }, 300);

    const handleScroll = (e) => {
      const OFFSET_TOP = device.isMainNav ? 96 : 48;
      if (wrapper?.current?.style) {
        wrapper.current.style.top = OFFSET_TOP - window.scrollY + 'px';
      }
      if (wrapper?.current?.offsetTop <= 0) {
        wrapper.current.style.top = '0px';
      }
    };

    // NOTE: threads doesn't support inline styles or refs so we have to hack the animation like so:
    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [device, pageLocale, userLocale]);

  // do not display foreign content notification on careers and blog
  if (/careers|blog/.test(parsedPath.pathname)) {
    return null;
  }

  if (isOpen && !NEXT_PUBLIC_PERCY_RUN) {
    return (
      <div className={styles.wrapper} ref={wrapper}>
        <Alert
          onClick={() => {
            return setIsOpen(false);
          }}
          icon={<CloseIcon />}
          title='hold it'
        >
          <>
            <span className={styles.message}>
              You're currently viewing our {pageLocale} site.
            </span>
            <Link href={localizedEquivalent} locale={userLocale?.localeCode}>
              <a className={styles.link}>
                Switch to the {userLocale?.localeCode} site
              </a>
            </Link>
          </>
        </Alert>
      </div>
    );
  }

  return null;
};

export default ForeignContentNotification;
