import React, { FC } from 'react';
import styles from './Date.module.scss';
import { format, parse } from 'fecha';
import cx from 'classnames';

interface Props {
  date: string;
  isUpdatedDate?: boolean;
  useDateStyles?: boolean;
  className?: string;
}

const Date: FC<Props> = ({
  date: dateString,
  isUpdatedDate = false,
  useDateStyles = false,
  className,
}) => {
  try {
    const formattedDate = format(
      parse(dateString, 'YYYY-MM-DD'),
      'MMMM DD, YYYY',
    );

    return (
      <p
        className={cx(
          styles.date,
          isUpdatedDate && !useDateStyles && styles.updatedDate,
          className,
        )}
      >
        {`${isUpdatedDate ? 'Updated on ' : ''}${formattedDate}`}
      </p>
    );
  } catch (e) {
    return null;
  }
};

const UpdatedDate: FC<Props> = ({ date }) => <Date date={date} isUpdatedDate />;

export { Date, UpdatedDate };
