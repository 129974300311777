import React, { useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { useDeviceSize } from '.';
import useIsPotato from './useIsPotato';
import { DEPLOY_ROOT, NEXT_PUBLIC_PERCY_RUN } from 'src/env';
import isEqual from 'lodash/isEqual';

type Axis =
  | number
  | string
  | {
      xs?: number | string;
      sm?: number | string;
      md?: number | string;
      lg?: number | string;
      xl?: number | string;
      xxl?: number | string;
    };

interface UseGuilloche3Props {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  disableAnimation?: boolean;
  gradient?: string;
  horizontalWave?: boolean;
  id?: string;
  intensity: number;
  intersectionThreshold?: number;
  rootMargin?: string;
  rotate?: Axis;
  scale?: Axis;
  scaleX?: Axis;
  scaleY?: Axis;
  speed: number;
  svgHeight: number;
  svgPaths: string[];
  svgWidth: number;
  verticalWave?: boolean;
  visibilityDelay?: number;
  x?: Axis;
  y?: Axis;
  zAxisWave?: boolean;
}

const useWorkerGuilloche = ({
  canvasRef,
  disableAnimation = false,
  gradient = 'https://images.ctfassets.net/ss5kfr270og3/7q1mJPw6bmeeUruloUUcxa/d69826c7922a24d66660ccd4e732a79a/3621384b921832f85bca12c7d2a4f5da?fm=webp&q=1',
  horizontalWave = true,
  id,
  intensity = 0.5,
  intersectionThreshold = 0.01,
  rootMargin = '0px 0px 0px 0px', // TODO: maybe expose this to the cms
  rotate = 0,
  scale,
  scaleX,
  scaleY,
  speed = 0.1,
  svgHeight,
  svgPaths = [],
  svgWidth,
  verticalWave = true,
  visibilityDelay = 1000,
  x,
  y,
  zAxisWave = true,
}: UseGuilloche3Props): void => {
  const isPotato = useIsPotato();
  const deviceSize = useDeviceSize();
  const [isVisible, setIsVisible] = useState(false);
  const [resizeTrigger, setResizeTrigger] = useState(0);

  const workerRef = useRef<Worker | null>(null);
  const prevPayloadRef = useRef<any>(null);

  // init worker
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!window.Worker || !canvas || !canvas.transferControlToOffscreen) {
      return;
    }

    const subfolder = DEPLOY_ROOT ? DEPLOY_ROOT.slice(0, 8) + '/' : '';
    const worker = new Worker(`/${subfolder}workers/guilloche.js`);
    workerRef.current = worker;

    // use for debugging
    worker.onmessage = function (event) {
      // eslint-disable-next-line no-console
      // console.log('Received result from worker:', event.data);
    };

    // transfer canvas to worker
    const offscreen = canvas.transferControlToOffscreen();
    worker.postMessage({ offscreen }, [offscreen]);

    return () => {
      worker.terminate();
      workerRef.current = null;
    };
  }, [canvasRef]);

  const debouncedPostMessage = useMemo(() => {
    return debounce((payload) => {
      if (workerRef.current && canvasRef.current) {
        workerRef.current.postMessage(payload);
      }
    }, 100);
  }, [workerRef, canvasRef]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    // window is not available in the worker so we get dpr here and pass it to the worker
    const deviceDpr = Math.min(window.devicePixelRatio || 1, 2);

    const newPayload = {
      clientHeight: canvasRef.current?.clientHeight,
      clientWidth: canvasRef.current?.clientWidth,
      deviceDpr,
      deviceSize,
      disableAnimation,
      gradient,
      horizontalWave,
      id,
      intensity,
      isPotato,
      isVisible,
      rotate,
      scale,
      scaleX,
      scaleY,
      speed,
      svgHeight,
      svgPaths,
      svgWidth,
      verticalWave,
      x,
      y,
      zAxisWave,
      NEXT_PUBLIC_PERCY_RUN,
    };

    // Only post if the payload has changed.
    if (!isEqual(newPayload, prevPayloadRef.current)) {
      prevPayloadRef.current = newPayload;
      debouncedPostMessage(newPayload);
    }
  }, [
    canvasRef,
    debouncedPostMessage,
    deviceSize,
    disableAnimation,
    gradient,
    horizontalWave,
    id,
    intensity,
    isPotato,
    isVisible,
    resizeTrigger,
    rotate,
    scale,
    scaleX,
    scaleY,
    speed,
    svgHeight,
    svgPaths,
    svgWidth,
    verticalWave,
    x,
    y,
    zAxisWave,
  ]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin, threshold: intersectionThreshold },
    );

    observer.observe(canvas);

    return () => {
      if (canvas) {
        observer.unobserve(canvas);
      }
      observer.disconnect();
    };
  }, [
    canvasRef,
    intersectionThreshold,
    isPotato,
    isVisible,
    rootMargin,
    visibilityDelay,
  ]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setResizeTrigger((prev) => {
        return prev + 1;
      });
    }, 100);

    window.addEventListener('resize', handleResize);

    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};

export default useWorkerGuilloche;
