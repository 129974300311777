import { Box, Stack } from 'src/components-v2/Layout';
import { IBtnProps } from './Btn.types';
import { ConditionalWrapper } from 'src/components-v2/Helpers';
import Link from '../Link/Link';
import { useTrack, trackingActions } from 'src/contexts/AnalyticsContext';
import styled from 'threads5/themes/styled';
import { Button as ButtonUnstyled } from '@mui/base/Button';
import { useIsDarkMode } from 'src/hooks/useIsDarkMode';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { v4 as uuidv4 } from 'uuid';

const StyledTertiaryButton = styled(ButtonUnstyled)(({ theme }) => {
  return {
    alignItems: 'center',
    border: 'none',
    borderRadius: '100px !important',
    display: 'inline-flex',
    gap: 1,
    fontFamily: theme.typography.sans,
    fontWeight: 600,
    letterSpacing: '-0.75px',
    lineHeight: '130%',
    color: `${theme.palette['web3/component/button/tertiary/text']}`,
    paddingRight: '6px',
    outline: 'none',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.0rem',
    },
    '&:focus-visible': {
      outlineWidth: '2px',
      outlineColor: theme.palette['web3/component/button/outline'],
      outlineStyle: 'solid',
      outlineOffset: '3px',
    },
    '&:hover, &:focus': {
      color: `${theme.palette['web3/component/button/tertiary/text']}`,
    },
    '& svg': {
      borderRadius: '100px',
    },
    '&:hover svg': {
      fill: theme.palette[
        'web3/component/button/tertiary/icon-container/interacted-fill'
      ],
      boxShadow:
        theme.palette['web3/component/button/tertiary/icon/hovered-glow'],
      '& path.arrow': {
        fill: theme.palette[
          'web3/component/button/tertiary/icon/fill-interacted'
        ],
      },
      '& path.circle': {
        stroke:
          theme.palette['web3/component/button/tertiary/icon/fill-interacted'],
      },
    },
    '&:active svg': {
      boxShadow: 'none',
      transition: 'transform 0.1s ease',
      transform: 'scale(0.96)',
    },
  };
});

const BtnTertiary = ({
  children,
  isInverted,
  trackingId,
  variant,
  sys,
  backgroundColor,
  iconName,
  iconSize,
  defaultGap,
  ...props
}: IBtnProps) => {
  const isDark = useIsDarkMode();
  const track = useTrack();
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  // generate a unique ID for the button svg
  // this cleans up dup ID errors, etc
  const uID = uuidv4();
  const circlePathId = uID + '-circle';
  const arrowPathId = uID + '-arrow';

  const handleClick = (event) => {
    if (trackingId) {
      track({
        type: trackingActions.BUTTON_CLICKED,
        payload: { trackingId, event, currentTarget: event.currentTarget },
      });
    }
    if (props.onClick && !props.href) {
      props.onClick(event);
    }
  };

  return (
    <ConditionalWrapper
      condition={props.href}
      wrapper={(children) => {
        return <Link href={props.href}>{children}</Link>;
      }}
    >
      <StyledTertiaryButton
        {...props}
        onClick={handleClick}
        {...inspectorProps({ fieldId: 'text' })}
      >
        <Stack component='span' direction='row' alignItems='center' gap={1}>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
          >
            <defs>
              <linearGradient
                id={circlePathId}
                x1='16.859'
                y1='600.296'
                x2='43.141'
                y2='621.463'
                gradientTransform='translate(-18 622.88) scale(1 -1)'
                gradientUnits='userSpaceOnUse'
              >
                <stop stopColor={isDark ? '#F9DBFF' : '#671877'} />
                <stop offset='0.5' stopColor={isDark ? '#E6E6FF' : '#2B2B82'} />
                <stop offset='1' stopColor={isDark ? '#CCF8FF' : '#043C67'} />
              </linearGradient>
              <linearGradient
                id={arrowPathId}
                x1='24.215'
                y1='605.565'
                x2='35.485'
                y2='616.446'
                xlinkHref={`#${circlePathId}`}
              >
                <stop stopColor={isDark ? '#F9DBFF' : '#671877'} />
                <stop offset='0.5' stopColor={isDark ? '#E6E6FF' : '#2B2B82'} />
                <stop offset='1' stopColor={isDark ? '#CCF8FF' : '#043C67'} />
              </linearGradient>
            </defs>
            <path
              className='circle'
              d='M 1 12 C 1 5.925 5.925 1 12 1 C 18.075 1 23 5.925 23 12 C 23 18.075 18.075 23 12 23 C 5.925 23 1 18.075 1 12 Z'
              stroke={`url(#${circlePathId})`}
              strokeWidth='1.5px'
              fill='none'
            />
            <path
              className='arrow'
              d='M 15.008 11.284 L 11.942 8.212 L 12.952 7.2 L 17.742 12 L 12.952 16.8 L 11.942 15.788 L 15.008 12.715 L 6.257 12.715 L 6.257 11.284 L 15.008 11.284 Z'
              fill={`url(#${arrowPathId})`}
            />
          </svg>
          <Box component='span'>{children}</Box>
        </Stack>
      </StyledTertiaryButton>
    </ConditionalWrapper>
  );
};

export default BtnTertiary;
