import { Typography } from 'src/components-v2/DataDisplay/Typography';
import { Stack } from 'src/components-v2/Layout';

const CmsStatsBlock = ({ fields, sys }) => {
  const { title, stat } = fields;
  return (
    <Stack
      className='stat-block'
      sx={{
        pt: '2rem',
        ...fields?.styles,
      }}
    >
      <Typography
        component='h4'
        variant='h6'
        className='stat-title'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: 2,
        }}
      >
        {title}
        <Typography
          component='span'
          variant='h1'
          className='stat-value'
          sx={{
            fontFamily: "'Bowery Street', sans-serif !important",
            fontSize: { xs: '4.5rem', md: '8.5rem' },
            fontWeight: 300,
            letterSpacing: '-5%',
            lineHeight: '100%',
          }}
        >
          {stat}
        </Typography>
      </Typography>
    </Stack>
  );
};

export default CmsStatsBlock;
