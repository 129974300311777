import React, { useEffect } from 'react';
import { IGuillocheDetail } from 'src/components-v3/Guilloche/Guilloche.types';

const guillocheModules = {
  dune: () => {
    return import('src/components-v3/Guilloche/Dune');
  },
  ribbon: () => {
    return import('src/components-v3/Guilloche/Ribbon');
  },
  donut: () => {
    return import('src/components-v3/Guilloche/Donut');
  },
  puddle: () => {
    return import('src/components-v3/Guilloche/Puddle');
  },
  fingerprint: () => {
    return import('src/components-v3/Guilloche/Fingerprint');
  },
};

const useLazyGuilloche = ({
  pattern,
  canvasRef,
  intersectionThreshold = 0.01,
  rootMargin = '0px 0px 0px 0px',
}: {
  pattern?: string;
  canvasRef: React.RefObject<HTMLCanvasElement>;
  intersectionThreshold?: number;
  rootMargin?: string;
}) => {
  const guilloche = React.useRef<IGuillocheDetail | null>(null);
  const [isVisible, setIsVisible] = React.useState(false);
  const [isGuillocheLoaded, setIsGuillocheLoaded] = React.useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          // we stop observing to avoid multiple calls
          observer.unobserve(canvas);
        }
      },
      { rootMargin, threshold: intersectionThreshold },
    );

    observer.observe(canvas);

    return () => {
      if (canvas) {
        observer.unobserve(canvas);
      }
      observer.disconnect();
    };
  }, [canvasRef, intersectionThreshold, isVisible, rootMargin]);

  useEffect(() => {
    const loadGuilloche = async () => {
      if (pattern) {
        const importer = guillocheModules[pattern];
        if (importer) {
          try {
            const webModule = await importer();
            guilloche.current = webModule.guillocheInfo;
            setIsGuillocheLoaded(true);
          } catch (error) {
            // do something
          }
        }
      }
    };

    if (isVisible) {
      loadGuilloche();
    }
  }, [pattern, isVisible]);

  return {
    guilloche: guilloche.current,
    isGuillocheLoaded,
    isVisible,
    isReady: isGuillocheLoaded && isVisible,
  };
};

export { useLazyGuilloche };
