import React from 'react';
import { RichTextSwitch } from 'src/containers';
import { Timeline, CmsSectionalHeader } from 'src/sections';
import { Image } from 'src/components-v2/Media';
import { isProduction } from 'src/lib/constants';
import { byVideoType } from 'src/lib/utils';
import { Box } from 'src/components-v2/Layout';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';
import ThemeProvider from 'threads5/themes/theme-provider';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import { timelineItemRichTextNodes } from '../Timeline.styles';

const CmsTimeline = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor || 'black';
  const cmsTheme = useCmsTheme();
  const cmsMode = useCmsMode({ mode: fields?.mode, backgroundColor: bgColor });
  const componentTheme = useComponentTheme(cmsMode);
  const background = componentTheme.palette[bgColor] || bgColor;

  // using this so background color gets set to provide correctly for v2 modes
  const legacyBgColor = cmsMode === 'dark' ? 'black' : 'white';

  const items = fields?.steps?.map((step, i) => {
    return {
      id: 'id-' + step.sys.id,
      isVisible: i === 0 ? true : false,
      asset: CmsTimelineAssetAdapter(step?.fields?.asset),
      content: (
        <Box
          sx={{
            '& h3': {
              mb: '3.2rem',
            },
            '& p': {
              mb: '32px',
            },
            '& p:last-child:not(:empty)': {
              mb: 0,
            },
            '& .cta-block, & .stat-block': {
              // It looks like maybe we're doing this to distinguish between
              // content with CTAs/links and basic content. But isn't that
              // what the cta-block is for? Hmm...
              ...(step?.fields?.body?.content?.length > 4
                ? {
                    mt: '24px',
                  }
                : {
                    mt: { xs: '12px', sm: '24px', md: '32px' },
                  }),
              '& p': {
                mb: '24px',
              },
            },
            // CTA blocks get dividing lines
            '& .cta-block': {
              borderTop: '1px solid',
              borderColor: componentTheme.palette['base/purple/100'],
              pt: { xs: '12px', sm: '24px', md: '32px' },
            },
            // we add this code to remove the trailing paragraph added by
            // contentful's rich text editor
            '& p:nth-last-of-type(1):empty': {
              display: 'none',
            },
          }}
        >
          <RichTextSwitch
            sys={step?.sys}
            content={step?.fields?.body}
            nodes={{ ...timelineItemRichTextNodes[cmsTheme] }}
          />
        </Box>
      ),
      sx: step?.fields?.styles,
      sys: step?.fields?.asset?.sys,
    };
  });
  return (
    <ThemeProvider theme={componentTheme}>
      <Timeline
        heading={
          <CmsSectionalHeader
            sys={fields?.header?.sys}
            fields={{
              ...fields?.header?.fields,
              backgroundColor: background,
              mode: cmsMode,
            }}
          />
        }
        items={items}
        sx={{ background, ...fields?.styles }}
        sys={sys}
        backgroundColor={legacyBgColor}
      />
    </ThemeProvider>
  );
};

const CmsTimelineAssetAdapter = (asset) => {
  switch (asset?.fields?.appearance) {
    case 'Image':
      const BASE = '?fm=webp&q=60';
      const XS_IMAGE = BASE + '&w=696';
      const SM_IMAGE = BASE + '&w=1108';
      const MD_IMAGE = BASE + '&w=1216';
      const LG_IMAGE = BASE + `&w=${Math.ceil(asset?.fields?.width * 2)}`;
      const lgImgSrc = 'https:' + asset?.fields?.largeImage?.fields?.file?.url;
      const mdImgSrc = asset?.fields?.mediumImage
        ? 'https:' + asset?.fields?.mediumImage?.fields?.file?.url
        : lgImgSrc;
      const smImgSrc = asset?.fields?.smallImage
        ? 'https:' + asset?.fields?.smallImage?.fields?.file?.url
        : mdImgSrc;
      return {
        src: lgImgSrc + LG_IMAGE,
        sources: [
          {
            media: '(max-width: 412px)',
            srcSet: smImgSrc + XS_IMAGE,
          },
          {
            media: '(min-width: 413px) and (max-width: 639px)',
            srcSet: smImgSrc + SM_IMAGE,
          },
          {
            media: '(min-width: 640px) and (max-width: 1023px)',
            srcSet: mdImgSrc + MD_IMAGE,
          },
          {
            media: '(min-width: 1024px)',
            srcSet: lgImgSrc + LG_IMAGE,
          },
        ],
        alt: asset?.fields?.description || '',
        width: asset?.fields?.width,
        height: asset?.fields?.height,
        sx: {
          margin: '4.0rem auto 6.0rem',
          display: { xs: 'block', md: 'none' },
          // NOTE: we do not pass asset styles from the cms here
          // Otherwise, they're only applied to the mobile asset
          // the mobile asset can be targeted in the timeline item style field
        },
        type: 'image',
        sys: asset?.sys,
        fieldId: 'internalTitle',
        // we pass styles so that a user can apply css overrides from
        // the CMS asset content type
        styles: asset?.fields?.styles,
      };
    case 'Video':
      const sortedVideos = asset?.fields?.video?.sort(byVideoType);
      return {
        sources: sortedVideos?.map((asset) => {
          return {
            src: asset?.fields?.file?.url,
            type: asset?.fields?.file?.contentType,
          };
        }),
        sx: {
          margin: '4.0rem auto 6.0rem',
          display: { xs: 'block', md: 'none' },
          // NOTE: we do not pass asset styles from the cms here
          // Otherwise, they're only applied to the mobile asset
          // the mobile asset can be targeted in the timeline item style field
        },
        type: 'video',
        sys: asset?.sys,
        fieldId: 'internalTitle',
        styles: asset?.fields?.styles,
      };

    case 'Lottie':
      const src = 'https:' + asset?.fields?.lottie?.fields?.file?.url;
      return {
        src: src,
        sx: {
          margin: '4.0rem auto 6.0rem',
          display: { xs: 'block', md: 'none' },
          // NOTE: we do not pass asset styles from the cms here
          // Otherwise, they're only applied to the mobile asset
          // the mobile asset can be targeted in the timeline item style field
        },
        type: 'lottie',
        sys: asset?.sys,
        fieldId: 'internalTitle',
        styles: asset?.fields?.styles,
      };

    default:
      if (!isProduction) {
        return (
          <>
            <Image
              width={600}
              height={400}
              src='https://placehold.co/600x400'
              alt='placeholder'
            />
          </>
        );
      } else {
        return null;
      }
  }
};

export default CmsTimeline;
