import { CmsThemes } from 'src/lib/constants';

export const cardContainerStyles = {
  [CmsThemes.WEB2]: {},
  [CmsThemes.WEB3]: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      borderRadius: '1.5rem',
      backgroundImage: (theme) => {
        return theme.palette['web3/component/shadow'];
      },
      filter: 'blur(8px)',
      transform: 'translateZ(0)',
    },
  },
};

export const cardStyles = {
  [CmsThemes.WEB2]: {
    borderRadius: '2.4rem',
    padding: { md: '4.8rem 5.4rem', xs: '2.8rem 3.2rem' },
    border: 'solid 1px',
    borderColor: 'black500',
    '& h3': {
      mb: '1.2rem',
    },
  },
  [CmsThemes.WEB3]: {
    position: 'relative',
    borderRadius: '1.5rem',
    padding: { md: '4.8rem 5.4rem', xs: '2.8rem 3.2rem' },
    backgroundColor: 'base/white',
    '& h3': {
      mb: '1rem',
    },
    mb: { xs: '16px', sm: '114px' },
  },
};

export const containerStyles = {
  [CmsThemes.WEB2]: { padding: { xs: '0 !important' } },
  [CmsThemes.WEB3]: { padding: { xs: '0 !important' } },
};

export const rowStyles = {
  [CmsThemes.WEB2]: {
    paddingLeft: { xs: '13px', sm: '40px' },
    paddingRight: { xs: '13px', sm: '40px' },
  },
  [CmsThemes.WEB3]: {
    paddingLeft: { xs: '13px', sm: '40px' },
    paddingRight: { xs: '13px', sm: '40px' },
  },
};

export const backgroundStyles = {
  [CmsThemes.WEB2]: {
    '& .background': {
      height: '100vh',
      width: '100vw',
      '& img': {
        mt: { xs: '305px' },
        mx: 'auto',
        display: 'block',
      },
    },
  },
  [CmsThemes.WEB3]: {},
};

export const spacerTopStyles = {
  [CmsThemes.WEB2]: {
    height: { xs: '250px', sm: '32px', lg: '125px' },
  },
  [CmsThemes.WEB3]: {
    height: { xs: '50px', sm: '50px', lg: '125px' },
  },
};

export const spacerMiddleStyles = {
  [CmsThemes.WEB2]: {
    height: { xs: '250px', sm: '32px', lg: '125px' },
  },
  [CmsThemes.WEB3]: {
    height: { xs: '200px', sm: '56px', lg: '125px' },
  },
};

export const spacerBottomStyles = {
  [CmsThemes.WEB2]: {
    height: { xs: '80px', sm: '170px', lg: '125px', xl: '300px' },
  },
  [CmsThemes.WEB3]: {
    height: { xs: '70px', sm: '0px', lg: '125px', xl: '300px' },
  },
};
