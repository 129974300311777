import React, { FC, useState } from 'react';
import Image from 'src/components/Image';

// components
import { Link } from 'src/components/Inputs';
import ArrowRightSingleSmall from 'src/components/SVGs/Icons/ArrowRightSingleSmall';
import { ModalPane } from 'src/components/Surfaces';
import { ConditionalWrapper } from 'src/components/helpers';
import { VideoEmbed } from 'src/components/VideoEmbed';

// helpers
import { Tile as TileProps } from './Tile.types';

// styles
import styles from './Tile.module.scss';

const Tile: FC<TileProps> = ({
  cta,
  image,
  title = '',
  description,
  target = '',
}) => {
  const [isModalOpen, setIsOpen] = useState(false);
  const newTabIndicator = target === 'New Tab' ? '_blank' : '';

  return (
    <>
      {target === 'Modal' && (
        <ModalPane setIsModalOpen={setIsOpen} isModalOpen={isModalOpen}>
          <VideoEmbed isLazy={true} url={cta.href} isInModal={true} />
        </ModalPane>
      )}
      <ConditionalWrapper
        condition={target !== 'Modal'}
        wrapper={(children) => {
          return (
            <div className={styles.tile} title={title}>
              {children}
            </div>
          );
        }}
        alternateWrapper={(children) => {
          return (
            <button
              type='button'
              onClick={() => {
                setIsOpen(true);
              }}
              className={styles.tile}
              title={title}
            >
              {children} {/* add <a> for styling purposes only*/}
            </button>
          );
        }}
      >
        <Image
          width={270}
          height={315}
          src={image.src}
          alt=''
          className={styles.logo}
          isLazy={true}
        />
        <div className={styles.text}>
          <p
            className={styles.excerpt}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <p className={styles.cta}>
            <ConditionalWrapper
              condition={target !== 'Modal'}
              wrapper={(children) => {
                return (
                  <Link href={cta.href}>
                    <a
                      className={styles.tile}
                      target={newTabIndicator}
                      title={title}
                    >
                      {children}
                    </a>
                  </Link>
                );
              }}
            >
              <span
                className={
                  target !== 'Modal'
                    ? 'feature-section-link-text'
                    : styles.linkText
                }
              >
                {cta.text}
                <span className={styles.ctaIcon}>
                  <ArrowRightSingleSmall />
                </span>
              </span>
            </ConditionalWrapper>
          </p>
        </div>
      </ConditionalWrapper>
    </>
  );
};

export default Tile;
