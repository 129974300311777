import React from 'react';
import { ImageProps } from './Image.types';
import styled from 'threads5/styles/styled';

// helpers
import { Lazy } from 'src/components-v2/Helpers';

const doBreakpoints = (breakpoints) => {
  if (!breakpoints) return null;
  return (
    <>
      {breakpoints?.xs && (
        <source
          media={`(max-width: 639px)`}
          srcSet={breakpoints?.xs}
          key='xs'
        />
      )}
      {breakpoints?.sm && (
        <source
          media={`(max-width: 1023px)`}
          srcSet={breakpoints?.sm}
          key='sm'
        />
      )}
      {breakpoints?.md && (
        <source
          media={`(max-width: 1279px)`}
          srcSet={breakpoints?.md}
          key='md'
        />
      )}
      {breakpoints?.lg && (
        <source
          media={`(max-width: 1439px)`}
          srcSet={breakpoints?.lg}
          key='lg'
        />
      )}
      {breakpoints?.xl && (
        <source
          media={`(max-width: 1729px)`}
          srcSet={breakpoints?.xl}
          key='xl'
        />
      )}
    </>
  );
};

const ImageComponent = styled('img')(() => {
  return {
    display: 'inline-block',
    height: 'auto',
    maxWidth: '100%',
    verticalAlign: 'middle',
  };
});

const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  (
    {
      isLazy = false,
      draggable = false,
      className = '',
      src = '',
      alt = '',
      width = '',
      height = '',
      sources = [],
      srcset = '',
      ...props
    },
    ref,
  ) => {
    return (
      <Lazy isLazy={isLazy} height={height}>
        <picture>
          {doBreakpoints(sources)}
          {Array.isArray(sources) &&
            sources?.map((item, i) => {
              return (
                <source
                  media={item.media}
                  srcSet={item.srcSet}
                  key={`image-source-${i}`}
                />
              );
            })}

          <ImageComponent
            className={className}
            src={src}
            alt={alt}
            width={width}
            height={height}
            ref={ref}
            draggable={draggable}
            srcSet={srcset}
            {...props}
          />
        </picture>
      </Lazy>
    );
  },
);

export default styled(Image)({});
