import { IBtnProps } from './Btn.types';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import { CmsThemes } from 'src/lib/constants';
import BtnTertiary from './BtnTertiary';
import BtnPrimary from './BtnPrimary';
import BtnSecondary from './BtnSecondary';
import BtnLegacy from './BtnLegacy';

const Btn = (props: IBtnProps) => {
  const cmsTheme = useCmsTheme();

  if (cmsTheme === CmsThemes.WEB3) {
    switch (props?.variant) {
      case 'primary':
        return <BtnPrimary {...props} />;
      case 'secondary':
        return <BtnSecondary {...props} />;
      case 'tertiary':
        return <BtnTertiary {...props} />;
    }
  }

  return <BtnLegacy {...props} />;
};

export default Btn;
