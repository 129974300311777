import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Sys } from 'contentful';
import * as React from 'react';
import { Background, Parallax } from 'react-parallax';
import {
  Box,
  Column,
  Container,
  Row,
  Section,
  Spacer,
} from 'src/components-v2/Layout';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import ThemeProvider from 'threads5/themes/theme-provider';
import {
  backgroundStyles,
  cardContainerStyles,
  cardStyles,
  containerStyles,
  rowStyles,
  spacerBottomStyles,
  spacerMiddleStyles,
  spacerTopStyles,
} from './ParallaxCards.styles';
import {
  ModeContextProvider,
  useCmsMode,
} from 'src/contexts/ModeContext/ModeContext';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import { CmsThemes } from 'src/lib/constants';
import useMeasure from 'react-use-measure';

interface Cards {
  content: React.ReactElement;
  sx?: SxProps<Theme>;
}

interface IParallaxCardsProps {
  backgroundAsset: React.ReactElement;
  blur?: number | { min: number; max: number };
  cards: Array<Cards>;
  heading?: React.ReactElement;
  // strength controls how much the background moves.
  // Greater = less bg movement and more foreground movement
  strength?: number;
  sx?: SxProps<Theme>;
  sys?: Sys;
  mode?: string;
  backgroundColor?: string;
}

const ParallaxCards: React.FunctionComponent<IParallaxCardsProps> = ({
  backgroundAsset,
  blur = 10,
  cards,
  heading,
  strength = 200,
  sx,
  sys,
  mode,
  backgroundColor,
}) => {
  const [containerRef, containerBounds] = useMeasure();

  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  const bgColor =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    backgroundColor || sx?.backgroundColor || sx?.bgColor || 'white';

  const cmsTheme = useCmsTheme();
  const cmsMode = useCmsMode({ mode: mode, backgroundColor: bgColor });
  const componentTheme = useComponentTheme(cmsMode);
  const background = componentTheme.palette[bgColor] || bgColor;

  return (
    <ThemeProvider theme={componentTheme}>
      <Section
        ref={containerRef}
        sx={{
          ...backgroundStyles[cmsTheme],
          '& h2': {
            mb: { xs: '2.1rem', sm: '2.4rem', md: '4.0rem' },
            textAlign: 'center',
          },
          '& h2:last-of-type': {
            mb: '0.8rem',
            textAlign: 'center',
          },
          background,
          ...sx,
        }}
        {...sx}
      >
        <Container
          sx={{
            ...containerStyles[cmsTheme],
            ...(cmsTheme === CmsThemes.WEB3 && {
              '& .background': {
                width: `${containerBounds?.width}px`,
                height: `${containerBounds?.height}px`,
                '& img': {
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: `${containerBounds?.width}px`,
                  height: `${containerBounds?.height}px`,
                },
              },
            }),
          }}
          {...inspectorProps({ fieldId: 'internalTitle' })}
        >
          <Parallax blur={blur} strength={strength}>
            {cmsTheme === CmsThemes.WEB3 && (
              <Spacer
                className='parallax-spacer-top'
                sx={spacerTopStyles[cmsTheme]}
              />
            )}
            <ModeContextProvider mode={cmsMode}>
              {heading && heading}
            </ModeContextProvider>
            <Spacer
              className='parallax-spacer-middle'
              sx={spacerMiddleStyles[cmsTheme]}
            />
            <Background className='background'>{backgroundAsset}</Background>
            <Row sx={rowStyles[cmsTheme]}>
              <Column lgOffset={1} lg={8} smOffset={0} sm={10}>
                <Card
                  mode={cmsMode}
                  backgroundColor={background}
                  sx={{
                    ...(cards?.[0]?.sx || {}),
                  }}
                >
                  {cards?.[0]?.content}
                </Card>
              </Column>
              <Column
                lgOffset={15}
                lg={8}
                smOffset={4}
                sm={10}
                sx={{ mt: { lg: '-9%', sm: '5.5%' } }}
              >
                <Card
                  mode={cmsMode}
                  backgroundColor={background}
                  sx={{
                    ...(cards?.[1]?.sx || {}),
                  }}
                >
                  {cards?.[1]?.content}
                </Card>
              </Column>
              <Column lgOffset={2} lg={8} smOffset={0} sm={10}>
                <Card
                  mode={cmsMode}
                  backgroundColor={background}
                  sx={{
                    ...(cards?.[2]?.sx || {}),
                  }}
                >
                  {cards?.[2]?.content}
                </Card>
              </Column>
              <Column
                lgOffset={14}
                lg={8}
                smOffset={4}
                sm={10}
                sx={{ mt: { lg: '-7.5%', sm: '8%' } }}
              >
                <Card
                  mode={cmsMode}
                  backgroundColor={background}
                  sx={{
                    ...(cards?.[3]?.sx || {}),
                  }}
                >
                  {cards?.[3]?.content}
                </Card>
              </Column>
            </Row>
            <Spacer
              className='parallax-spacer-bottom'
              sx={spacerBottomStyles[cmsTheme]}
            />
          </Parallax>
        </Container>
      </Section>
    </ThemeProvider>
  );
};

export default ParallaxCards;

const Card = ({ children, sx, backgroundColor, mode }) => {
  const cmsTheme = useCmsTheme();
  const theme = useComponentTheme(cmsTheme === CmsThemes.WEB3 ? 'light' : mode);
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ ...cardContainerStyles[cmsTheme] }}>
        <Box
          sx={{
            ...cardStyles[cmsTheme],
            ...(cmsTheme === CmsThemes.WEB2 && {
              backgroundColor: backgroundColor,
            }),
            '& p': {
              mb: 0,
            },
            '& p:nth-last-of-type(1):empty': {
              display: 'none',
            },
            ...sx,
          }}
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
