import React from 'react';
import PropTypes from 'prop-types';
import styles from './Showcase.module.scss';
import cx from 'classnames';
import { ImageShape, VideoShape } from 'src/lib/prop-types';

//components
import Image from 'src/components/Image';
import CtaButton from 'src/components/Inputs/CtaButton/CtaButton';
import ShowcaseCard from 'src/components/showcase-card';
import { MarkdownRenderer } from 'src/components/Blog/MarkdownRenderer';
import { Grid, Column } from 'src/components/Layout/Grid';

// helpers
import { optimizeImage } from 'src/lib/image-helpers';

const propTypes = {
  bgColor: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.array.isRequired,
  ]),
  hasCta: PropTypes.bool,
  cta: PropTypes.shape({
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  image: ImageShape,
  video: VideoShape,
  brushstroke: PropTypes.shape({
    src: PropTypes.string.isRequired,
    size: PropTypes.string,
    position: PropTypes.string,
  }),
  illustration: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
  }),
  cards: PropTypes.array,
  className: PropTypes.string,
  flipped: PropTypes.bool,
  fullWidthImage: PropTypes.bool,
  dropshadow: PropTypes.bool,
  useThreadsGrid: PropTypes.bool,
};

const Showcase = ({
  bgColor = '',
  label,
  title,
  description,
  hasCta = false,
  cta,
  image,
  video,
  brushstroke,
  cards,
  illustration,
  className,
  flipped = false,
  fullWidthImage = false,
  dropshadow,
  useThreadsGrid = false,
}) => {
  const { text, ...ctaProps } = cta;

  return (
    <article
      className={cx(styles.showcase, styles[className], {
        [`${styles[bgColor]}`]: bgColor,
      })}
    >
      <div
        className={cx(styles.showcaseContainer, {
          [styles.withCards]: cards?.length,
        })}
      >
        <Grid
          className={cx({
            [styles.showcaseFlipped]: flipped,
          })}
          useThreadsGrid={useThreadsGrid}
          noPadding
          excludeGridContainerClass
        >
          <Column large={5}>
            <section className={styles.showcaseSetup}>
              {label && <h2 className={cx(styles.label, 'h6')}>{label}</h2>}
              <h3 className={styles.showcaseTitle}>{title}</h3>
              <div className={styles.showcaseDescription}>
                {Array.isArray(description) ? (
                  description.map((desc, i) => {
                    return <MarkdownRenderer body={desc} key={`desc-${i}`} />;
                  })
                ) : (
                  <MarkdownRenderer body={description} />
                )}
              </div>
              {hasCta && (
                <div className={styles.showcaseCta}>
                  <CtaButton {...ctaProps}>{text}</CtaButton>
                </div>
              )}
            </section>
          </Column>
          <Column large={7}>
            <section className={styles.showcaseExhibit}>
              {brushstroke && (
                <div
                  className={styles.showcaseBrushstroke}
                  style={{
                    backgroundImage: `url(${brushstroke.src})`,
                    backgroundPosition: brushstroke?.position || '',
                    backgroundSize: brushstroke?.size || '',
                  }}
                  data-testid='showcase-accent'
                />
              )}
              <div className={styles.showcaseObjects}>
                {video?.src && (
                  <>
                    <video
                      autoPlay
                      muted
                      loop
                      width={video?.width}
                      height={video?.height}
                      data-testid='showcase-video'
                      className={cx(styles.showcaseVideo, 'show-for-medium', {
                        [styles.showcaseVideoFullWidth]: fullWidthImage,
                        [styles.showcaseDropshadow]: dropshadow,
                      })}
                      poster={!!image?.src ? optimizeImage(image.src) : null}
                    >
                      <source src={video.src} type={video.type} />
                    </video>
                    {image?.src && (
                      <Image
                        className={cx(styles.showcaseImage, 'hide-for-medium', {
                          [styles.showcaseImageFullWidth]: fullWidthImage,
                        })}
                        src={image.src}
                        alt={image.alt}
                      />
                    )}
                  </>
                )}
                {image?.src && !video?.src && (
                  <Image
                    className={cx(styles.showcaseImage, {
                      [styles.showcaseImageFullWidth]: fullWidthImage,
                    })}
                    alt={`${image?.alt}`}
                    {...image}
                  />
                )}
                {cards &&
                  cards.map((card, i) => {
                    return (
                      <ShowcaseCard
                        {...card}
                        index={i}
                        key={`showcase-card-${i}`}
                      />
                    );
                  })}
              </div>
              {illustration && (
                <div className={styles.showcaseArt}>
                  <Image src={illustration.src} alt={illustration.alt || ''} />
                </div>
              )}
            </section>
          </Column>
        </Grid>
      </div>
    </article>
  );
};

Showcase.propTypes = propTypes;

export default Showcase;
