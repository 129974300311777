import { CmsThemes } from 'src/lib/constants';

export const buttonGroupStyles = {
  [CmsThemes.WEB2]: {},
  [CmsThemes.WEB3]: {
    '& > a': {
      width: { xs: '100%', sm: 'unset' },
      justifyContent: 'center',
    },
  },
};
