import React, { FC, useState } from 'react';
import styled from 'threads5/styles/styled';
import { animated, useSpring, easings } from '@react-spring/web';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { colors } from 'src/components-v2/theme';
import { IFeatureCarouselProps } from './FeatureCarousel.types';
import {
  Column,
  Section,
  Container,
  Row,
  Stack,
} from 'src/components-v2/Layout';
import { FeatureListItem } from './FeatureListItem';
import { Image } from 'src/components-v2/Media';

const AnimatedStack = animated(Stack);

const UnorderedList = styled('ul')(({ theme }) => {
  return {
    backgroundColor: colors.black200,
    border: `1px ${colors.black500} solid`,
    borderRadius: '1.6rem',
    listStyle: 'none',
    overflow: 'hidden',
    marginBottom: 0,
  };
});

const BASE = '?fm=webp&q=60';
const XS_IMAGE = BASE + '&w=1214';
const SM_IMAGE = BASE + '&w=836';
const MD_IMAGE = BASE + '&w=1072';
const LG_IMAGE = BASE + '&w=1340';

const getCurrentItem = (state) => {
  return state.findIndex((item) => {
    return item.isActive === true;
  });
};

const FeatureCarousel: FC<IFeatureCarouselProps> = ({
  children,
  fieldId = '',
  interval,
  items,
  sx = {},
  sys,
}) => {
  const inspectorProps = useContentfulInspectorMode({ entryId: sys?.id });
  const initialState = items.map((item, index) => {
    return {
      isActive: index === 0 ? true : false,
    };
  });
  const [state, setState] = useState(initialState);

  const handleClick = (index) => {
    setState((prevState) => {
      return prevState.map((item, i) => {
        return { isActive: i === index ? true : false };
      });
    });
  };

  const setNextItem = () => {
    const currentItem = getCurrentItem(state);

    setState((prevState) => {
      if (currentItem === items.length - 1) {
        return initialState;
      }

      return prevState.map((item, index) => {
        return { isActive: index === currentItem + 1 ? true : false };
      });
    });
  };

  const currentItem = getCurrentItem(state);

  const fadeInImage = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: {
      duration: 300,
      easing: easings.easeInQuad,
    },
    reset: true,
  });

  return (
    <Section
      aria-label='Carousel. Instructions: Use the tab button to cycle through items'
      sx={sx}
      tabindex={0}
      {...inspectorProps({
        fieldId,
      })}
    >
      <Container>
        <Row
          sx={{
            flexDirection: { xs: 'column-reverse', sm: 'row' },
          }}
        >
          <Column xs={24} sm={11} lg={10} lgOffset={2}>
            <AnimatedStack
              direction='column'
              justifyContent='center'
              sx={{ mt: { xs: '2.4rem', sm: 0 }, height: '100%' }}
              style={fadeInImage}
            >
              <Image
                {...inspectorProps({
                  // @ts-ignore
                  entryId: items[currentItem]?.image?.sys?.id,
                  fieldId: items[currentItem]?.image?.fieldId,
                })}
                src={items[currentItem]?.image.src}
                alt={items[currentItem]?.image.alt}
                width={items[currentItem]?.image.width}
                height={items[currentItem]?.image.height}
                isLazy={items[currentItem]?.image.isLazy}
                sources={[
                  {
                    media: '(max-width: 639px)',
                    srcSet: items[currentItem]?.image.src + XS_IMAGE,
                  },
                  {
                    media: '(min-width: 640px) and (max-width: 1023px)',
                    srcSet: items[currentItem]?.image.src + SM_IMAGE,
                  },
                  {
                    media: '(min-width: 1024px) and (max-width: 1279px)',
                    srcSet: items[currentItem]?.image.src + MD_IMAGE,
                  },
                  {
                    media: '(min-width: 1280px)',
                    srcSet: items[currentItem]?.image.src + LG_IMAGE,
                  },
                ]}
              />
            </AnimatedStack>
          </Column>
          <Column xs={24} sm={12} smOffset={1} lg={9} lgOffset={1}>
            <Stack
              justifyContent='center'
              sx={{
                height: '100%',
                '& h3': {
                  mb: { xs: '1.6rem', md: '0.8rem' },
                },
                '& p': {
                  mb: { xs: '3.2rem', sm: '4rem', lg: '5.6rem' },
                },
                '& ul > li > div > p': {
                  // for paragraph in FeatureListItem
                  mb: 0,
                },
              }}
            >
              {children}
              <UnorderedList sx={{ ml: 0 }}>
                {state.map((item, index) => {
                  return (
                    <FeatureListItem
                      handleClick={handleClick}
                      index={index}
                      interval={interval}
                      isActive={item.isActive}
                      isLast={index === items.length - 1}
                      key={`fli-${index}`}
                      setNextItem={setNextItem}
                      aria={items[index]?.aria}
                      sx={items[index].sx}
                    >
                      {items[index].content}
                    </FeatureListItem>
                  );
                })}
              </UnorderedList>
            </Stack>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default FeatureCarousel;
