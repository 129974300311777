import * as React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Wrapper } from 'src/containers';
import { Column, Stack } from 'src/components-v2/Layout';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { CmsMediaSwitch } from 'src/components-v2/Media/CmsMediaSwitch';
import { useCmsMode } from 'src/contexts/ModeContext/ModeContext';
import { useComponentTheme } from 'src/hooks/useComponentTheme';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';
import ThemeProvider from 'threads5/themes/theme-provider';
import { buttonGroupStyles } from './CmsLeftRightHero.styles';

const CmsLeftRightHero = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor?.toLowerCase() || 'transparent';
  const mode = useCmsMode({
    mode: fields?.mode,
    backgroundColor: bgColor,
  });
  const componentTheme = useComponentTheme(mode);
  const cmsTheme = useCmsTheme();
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });

  const background = componentTheme.palette[bgColor] || bgColor;

  return (
    <ThemeProvider theme={componentTheme}>
      <Wrapper
        component='header'
        sx={{ background, ...fields?.styles }}
        {...inspectorProps({ fieldId: 'internalTitle' })}
      >
        <Column
          xs={24}
          sm={10}
          lg={9}
          lgOffset={2}
          sx={{ mb: { xs: '1.8rem', sm: '0' } }}
        >
          <Stack
            sx={{
              justifyContent: 'center',
              height: '100%',
              '& .eyebrow-heading, .decorative-text': {
                mb: { xs: '2.4rem', sm: '3.2rem', lg: '4rem' },
              },
              '& h1:not(:last-child), & h2:not(:last-child), & h3:not(:last-child), & h4:not(:last-child), & h5:not(:last-child)':
                {
                  mb: { xs: '2rem', md: '3.2rem' },
                },
              '& p': {
                mb: { xs: '2rem', md: '3.2rem' },
              },
              '& p:nth-last-of-type(1):empty': {
                display: 'none',
              },
              '& .button-group': {
                flexDirection: { xs: 'column', sm: 'row' },
                flexWrap: 'wrap',
                gap: { xs: '1.8rem', sm: '1.2rem', lg: '2.6rem' },
                ...buttonGroupStyles?.[cmsTheme],
              },
              // for decorative text
              '& p:has(.decorative-text), h1:has(.decorative-text)': {
                mb: { xs: 1, md: 2 },
                // for icons
                '& svg': {
                  mt: { xs: '0.2rem', md: '0.4rem' },
                  zoom: { xs: 1, md: 1.2 }, // we use zoom because it works for all icons
                },
              },
            }}
          >
            <RichTextSwitch
              sys={sys}
              content={fields?.leftColumn}
              nodes={{
                h1: {
                  component: 'h1',
                  variant: 'h1',
                  fieldId: 'leftColumn',
                },
                h2: {
                  component: 'h2',
                  variant: 'h1',
                  fieldId: 'leftColumn',
                },
                h3: {
                  component: 'h2',
                  variant: 'h2',
                  fieldId: 'leftColumn',
                },
                h6: {
                  component: 'h1',
                  variant: 'h6',
                  className: 'eyebrow-heading',
                  fieldId: 'leftColumn',
                },
                p: {
                  component: 'p',
                  variant: 'h5',
                  fieldId: 'leftColumn',
                },
              }}
            />
          </Stack>
        </Column>

        <Column xs={24} smOffset={1} sm={13} lg={10}>
          <Stack
            sx={{
              justifyContent: { sm: 'flex-end' },
              mr: { sm: '-4rem' },
              height: '100%',
            }}
          >
            <CmsMediaSwitch
              asset={{
                ...fields?.asset?.fields,
                isLazy: false,
                styles: {
                  height: 'auto',
                  width: '100%',
                  ...fields?.asset?.fields?.styles,
                },
              }}
              queryString={{
                xs: '?fm=webp&w=768&q=60',
                sm: '?fm=webp&w=1222&q=60',
                md: '?fm=webp&w=1012&q=60',
                lg: '?fm=webp&w=1354&q=60',
              }}
              width={677} // setting a default width in case it was not provided in CMS
              height={513} // setting a default height in case it was not provided in CMS
              sys={fields?.asset?.sys}
            />
          </Stack>
        </Column>
      </Wrapper>
    </ThemeProvider>
  );
};

export default CmsLeftRightHero;
