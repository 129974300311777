import { CtaBanner } from 'src/sections';
import { RichTextSwitch } from 'src/containers/RichTextSwitch';
import { Btn as Button } from 'src/components-v2/Inputs';
import { CmsThemes, targetMap } from 'src/lib/constants';
import { useCmsTheme } from 'src/contexts/CmsThemeContext/CmsThemeContextProvider';

export const CmsCtaHeaderBanner = ({ fields, sys }) => {
  const bgColor = fields?.backgroundColor?.toLowerCase() || 'white';
  const bgImage = fields?.backgroundImage?.fields?.file?.url;
  const cmsTheme = useCmsTheme();
  const isV2 = cmsTheme === CmsThemes.WEB2;

  const paragraphIncluded = fields?.body?.content?.some((item) => {
    if (item.nodeType === 'paragraph') {
      if (item.content[0].value.length > 1) {
        return true;
      }
    }
    return false;
  });

  return (
    <CtaBanner
      sx={{
        ...(paragraphIncluded && {
          '& h2': {
            width: { xl: '38%' },
          },
          '& p': {
            width: { xl: '33%' },
          },
        }),
        '& a': {
          whiteSpace: 'nowrap',
        },
        ...fields?.styles,
      }}
      sys={sys}
      bgImage={bgImage}
      mode={fields?.mode}
      intrinsicMode={fields?.intrinsicMode || fields?.mode}
      backgroundColor={bgColor}
    >
      <RichTextSwitch
        sys={sys}
        content={fields?.body}
        nodes={{
          h2: {
            variant: paragraphIncluded ? 'h3' : 'h2',
            component: 'h2',
            fieldId: 'body',
          },
          p: {
            variant: 'p2',
            sx: {
              ...(isV2 && {
                color: (theme) => {
                  return theme.palette[
                    bgImage ? 'base/black/800' : 'base/black/200'
                  ];
                },
              }),
            },
          },
        }}
      />
      <Button
        variant={'primary'}
        href={fields?.cta?.fields?.url || ''}
        target={targetMap[fields?.cta?.fields?.target] || '_self'}
        trackingId={fields?.cta?.fields?.trackingId || ''}
        sys={fields?.cta?.sys}
      >
        {fields?.cta?.fields?.text}
      </Button>
    </CtaBanner>
  );
};
