import SxProps from 'threads5/themes/sx-props';
import Theme from 'threads5/themes/theme';
import React from 'react';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { Sys } from 'contentful';
import { Box, Column, Container, Row, Section } from 'src/components-v2/Layout';

interface ICardsGrid {
  heading?: React.ReactElement;
  cards: Array<React.ReactElement>;
  sx?: SxProps<Theme>;
  sys?: Sys;
}

const CardsGrid: React.FC<ICardsGrid> = ({ heading, cards, sx, sys }) => {
  const inspectorProps = useContentfulInspectorMode({
    entryId: sys?.id,
  });
  return (
    <Section
      className='cms-cards-grid'
      sx={{
        ...sx,
        '& h2': {
          mb: '0.8rem',
        },
      }}
      {...sx}
    >
      {heading && heading}
      <Container {...inspectorProps({ fieldId: 'internalTitle' })}>
        <Row>
          <Column
            xs={22}
            xsOffset={1}
            sm={24}
            smOffset={0}
            lg={20}
            lgOffset={2}
          >
            <Box
              sx={{
                display: 'grid',
                gap: { xs: 2, sm: 1, lg: 6 },
                gridTemplateColumns: {
                  xs: '1fr',
                  sm: 'repeat(2,45%)',
                  md: 'repeat(2,40%)',
                  lg: '1fr 1fr 1fr',
                },
                justifyContent: 'center',
                gridAutoRows: '1fr',
              }}
            >
              {cards.map((card, index) => {
                return <Box key={`cards-grid-card-${index}`}>{card}</Box>;
              })}
            </Box>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

export default CardsGrid;
